import React, {useCallback, useEffect, useRef, useState} from "react";
import {debounce} from "lodash";
import useLoadGoogleMaps from "./useLoadGoogleMaps";

export default function useAddressPredictions(types = ['(cities)']) {

	const autocomplete = useRef();

	const {isLoaded} = useLoadGoogleMaps();

	useEffect(() => {

		if(!autocomplete.current && isLoaded && window.google.maps.places) {

			autocomplete.current = new window.google.maps.places.AutocompleteService();

		}

	}, [isLoaded]);

	const [loading, setLoading] = useState(true);

	const [predictions, setPredictions] = useState([]);

	const [address, setAddress] = useState('');

	useEffect(() => {

		setLoading(true);

		debouncedGetPlacePredictions(address);

		if(address === '') {

			setLoading(false);

			setPredictions([]);

		}

	}, [address]);

	function getPlacePredictions(input) {

		if(autocomplete.current) {

			autocomplete.current.getPlacePredictions(
				{
					input,
					componentRestrictions: {
						country: "us",
					},
					language: 'en',
					types: types
				},

				predictions => {

					setPredictions(predictions || []);

					setLoading(false);

				}
			);

		}

	}

	const debouncedGetPlacePredictions = useCallback(
		debounce(getPlacePredictions, 500),
		[]);

	return {
		predictions,
		loading,
		setLoading,
		address,
		setAddress
	};

}
