import CloseOutside from "./CloseOutside";
import usePopup from "../../Hooks/ReduxHooks/usePopup";
import CloseIcon from "./CloseIcon";
import {Rings} from "react-loader-spinner";
import React from "react";

const ConfirmationPopup = ({title, text, confirmLoading, callback}) => {

	const {closePopup} = usePopup();

	return(
		<CloseOutside handleClose={closePopup}>
			<div className={'bg-white rounded-4 pt-4 pb-4 ps-4 pe-4 mt-4 position-relative'}>
				<CloseIcon handleClose={closePopup} additionalStyles={'mt-3 me-3'} />
				<div className={'mt-3'}>
					<h3 className={'font-xxxl mb-2'}>
						{title}
					</h3>
					<p className={'delete-card-text font-lg font-family-open-sans text-gray-444'}>
						{text}
					</p>
					<div className={'d-flex'}>
						<button type={'button'} className={'btn w-50 btn-gray-ccc me-2'} onClick={closePopup}>
							Cancel
						</button>
						<button type={'button'} className={'btn w-50 btn-danger d-flex justify-content-center align-items-center'} disabled={confirmLoading} onClick={callback}>
							{confirmLoading ? <Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/> : "Confirm"}
						</button>
					</div>
				</div>
			</div>
		</CloseOutside>
	)
}

export default ConfirmationPopup;