import React, {useState} from 'react'
import {PaymentElement} from "@stripe/react-stripe-js";
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {Rings} from "react-loader-spinner";

const StripeCheckout = () => {

	const stripe = useStripe();
	const elements = useElements();
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const {error} = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: `${window.location.origin}/account/billing`,
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	}

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement />
			<button disabled={isLoading || !stripe || !elements}
							className="btn btn-primary mt-3 font-lg d-flex justify-content-center" id="submit">
				<span id="button-text">
					{isLoading ? <Rings height="28" width="28" color="#fff" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/> : "Pay now"}
				</span>
			</button>
		</form>
	);
};

export default StripeCheckout;