import React, {useState} from "react";
import FreeRepeater from '../Common/FreeRepeater';
import {Col, Form, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import AppConfig from '../../../../../Config/AppConfig';
import {useFormikContext} from 'formik';
import CloseOutside from '../../../../Parts/CloseOutside';
import AnimatedDropdown from '../../../../Parts/AnimatedDropdown';
import ProfileError from "../../Parts/ProfileError";
import {X} from "react-feather";
import TabLocations from "./TabLocations";

const DisplayPredefinedRepeater = ({section, title}) => {

	const {values, setFieldValue} = useFormikContext();

	const { t } = useTranslation();

	const [hintsOpened, setHintsOpened] = useState(false)

	const config = AppConfig.job[section];

	let options = [];

	const data = (Array.isArray(values[section])) ? values[section] : [];

	Object.keys(config).map(id => {

		if(!data.includes(id)) {

			options.push({
				id: id,
				name: t(`config.appConfig.job.${section}.${config[id]}`)
			});

		}

	});

	const parseItems = () => {

		let items = [];

		data.forEach((id) => {
			items.push({
				id: id,
				name: t(`config.appConfig.job.${section}.${config[id]}`)
			});
		});

		return items;

	}

	const assignHint = (item, finished) => {

		setFieldValue(section, data.concat(item.id));

	}

	const handleRemove = (item) => {

		setFieldValue(section, data.filter((id) => {

			return (
				item.id !== id
			)

		}));

	};
	return (
		<>
			<div className={`d-flex align-items-center mt-4 mb-2 position-relative repeater-title ${section === 'languages' ? 'w-100' : ''}`}>
				<h4 className={'m-0 title'}>{title}</h4>
			</div>
			<div className={`d-flex flex-wrap ${section === 'languages' ? 'w-75 pe-3' : ''}`}>
				<div className="w-100 position-relative">
					<div className={`profile-repeater-wrapper`}>
						<div className="d-flex flex-wrap">
							{parseItems().map((item, id) => (
								<span key={id} className={`repeater-pill font-family-open-sans`}>
									{item.name}
									<X size={16} className={'ms-1'} onClick={() => handleRemove(item)} />
								</span>
							))}
						</div>
						<div className={'meta-add-input form-control-with-loader'}>
							<div className="d-flex justify-content-between">
								<button className={'btn expectations-button'} type={'button'} onClick={() => setHintsOpened(prevState => !prevState)}>
									Enter and find
								</button>
							</div>
							{hintsOpened &&
								<div className={`results mt-2`}>
									{options.map((hint, index) => (
										<button key={index} type={'button'} className="result" onClick={() => assignHint(hint)}>
											{hint.name}
										</button>
									))}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);

}

const FormEarning = () => {
	const {values, setFieldValue, errors} = useFormikContext();
	const { t } = useTranslation();
	const salaryTypeConfig = AppConfig.job.salary_type;

	const SalaryTypeSelect = () => {

		const [visibleDropdownSelect, setVisibleDropdownSelect] = useState(false)

		return(
			<CloseOutside handleClose={() => setVisibleDropdownSelect(false)}>
				<div className="position-relative">
					<button type={'button'} className="font-family-open-sans btn bg-white p-0 profile-repeater-wrapper shadow-sm pt-3 pb-3 ps-1 font-md pe-1 w-100" onClick={() => setVisibleDropdownSelect(prevState => !prevState)}>
						{!values.salary_type ? t('profile.job.form.tabRequirements.formEarnings.salaryTypeSelect.button') : t(`config.appConfig.job.salary_type.${salaryTypeConfig[values.salary_type]}`)}
					</button>
					<AnimatedDropdown isOpen={visibleDropdownSelect} className="d-flex flex-column w-100">
						{Object.keys(salaryTypeConfig).map((salaryType, index) => <p key={index} className="font-family-open-sans btn rounded-0 mb-0 font-md ps-0 pe-0" onClick={() => setFieldValue('salary_type', salaryType)}>{t(`config.appConfig.job.salary_type.${salaryTypeConfig[salaryType]}`)}</p>)}
					</AnimatedDropdown>
				</div>
			</CloseOutside>
		)
	}

	return(
		<>
			<div className="d-flex align-items-center mt-4 mb-2 position-relative repeater-title">
				<h2 className="m-0 title">Salary</h2>
			</div>
			<Row className="profile-repeater-wrapper">
				<Col xs={4} md={3} className="mb-3 mb-md-0 position-relative">
					<Form.Control placeholder={t('profile.edit.tabs.content.tabRequirements.formEarnings.from')} className={`pt-3 pb-3 ${errors.salary_from ? 'border border-danger' : ''}`} value={values.salary_from} onChange={(e) => setFieldValue('salary_from', e.target.value)} />
					<ProfileError profileError={errors.salary_from} additionalStyles={'position-absolute end-0 top-50 translate-middle-y me-3'} />
				</Col>
				<div className="d-flex align-items-center w-auto p-0">
					-
				</div>
				<Col xs={4} md={3} className="mb-3 mb-md-0 position-relative">
					<Form.Control placeholder={t('profile.edit.tabs.content.tabRequirements.formEarnings.to')} className={`pt-3 pb-3 ${errors.salary_to ? 'border border-danger' : ''}`} value={values.salary_to} onChange={(e) => setFieldValue('salary_to', e.target.value)} />
					<ProfileError profileError={errors.salary_to} additionalStyles={'position-absolute end-0 top-50 translate-middle-y me-3'} />
				</Col>
				<div className="d-flex align-items-center w-auto p-0">
					$
				</div>
				<Col xs={12} md={5}>
					<SalaryTypeSelect />
				</Col>
			</Row>
		</>
	)
}

const TabRequirements = () => {

	const { t } = useTranslation();

	return (
		<>
			<Row>
				<Col sm={12} lg={6}>
					<DisplayPredefinedRepeater title={t('profile.edit.tabs.content.tabRequirements.displayPredefinedRepeater.contract')} section={'contract'}/>
				</Col>
				<Col sm={12} lg={6}>
					<DisplayPredefinedRepeater title={t('profile.edit.tabs.content.tabRequirements.displayPredefinedRepeater.time')} section={'working_time'}/>
				</Col>
			</Row>
			<Row>
				<Col sm={12} lg={6}>
					<DisplayPredefinedRepeater title={t('profile.edit.tabs.content.tabRequirements.displayPredefinedRepeater.mode')} section={'working_mode'} />
				</Col>
				<Col sm={12} lg={6}>
					<FormEarning />
				</Col>
			</Row>
			<TabLocations />
			<FreeRepeater title={t('profile.profile.tabs.profileTab.index.sections.requirements.title')} buttonTitle={t('profile.profile.tabs.profileTab.index.sections.requirements.buttonTitle')} section={'requirements'}/>
		</>
	);
};

export default TabRequirements;
