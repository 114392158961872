import React, {useEffect, useState} from "react";
import {Container} from 'react-bootstrap';
import {Page} from '../Layout/Page';
import {useTranslation} from 'react-i18next';
import TopBar from './TopBar';
import {useInstantSearch} from "react-instantsearch";
import useUser from "../../Hooks/ReduxHooks/useUser";
import '../Browse/Browse.scss';
import {BrowseInfiniteHits, BrowseInfiniteHitsLoader} from "../Browse/Browse";

const Observations = () => {

	const {t} = useTranslation();

	const {user} = useUser();

	const {setIndexUiState} = useInstantSearch();

	const [ready, setReady] = useState(false);

	const observations = user.observations;

	const observationsListObjectID = observations.map(el => 'objectID:user-' + el);

	const {handleSetGeo} = useUser();

	useEffect(() => {

		handleSetGeo({
			selected: false,
			radius: 50,
			lat: false,
			lng: false,
			name: '',
		});

		setIndexUiState((prev) => {
			return {
				...prev,
				configure: {
					...prev.configure,
					filters: observationsListObjectID.join(' OR ')
				}
			}
		});

		setReady(true)

	}, []);

	return (
		<Page title={t('pageTitles.observations')}>
			<section className="profile">
				<Container>
					<TopBar title={t('profile.observations.title')} desc={t('profile.observations.text')}/>
					<div className={'mt-4'}>
						{ready ? <BrowseInfiniteHits/> : <BrowseInfiniteHitsLoader/>}
					</div>
				</Container>
			</section>
		</Page>
	);

};

export default Observations;
