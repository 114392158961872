import TopPanel from "./Parts/TopPanel";
import ChatContent from "./Parts/ChatContent";
import BottomPanel from "./Parts/BottomPanel";
import {useRef} from "react";
import useMessage from "../../../Hooks/ReduxHooks/useMessage";
import useUser from "../../../Hooks/ReduxHooks/useUser";
import {Alert} from "react-bootstrap";

const Chat = () => {

	const {isMobile} = useUser();

	const endOfMessagesRef = useRef(null);

	const chatContainerRef = useRef(null);

	const {handleSetIsNewMessage, chatID} = useMessage();

	if(isMobile && !chatID){
		return
	}

	const scrollToBottom = () => {
		chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
		handleSetIsNewMessage(false);
	}

	return(
		<>
			{chatID ?
				<div className="message-popup position-relative d-md-block d-flex flex-column justify-content-between">
					<TopPanel />
					<ChatContent endOfMessagesRef={endOfMessagesRef} scrollToBottom={scrollToBottom} chatContainerRef={chatContainerRef} />
					<BottomPanel scrollToBottom={scrollToBottom} />
				</div>
				:
				<Alert variant={'info'}>Select conversations to view messages</Alert>
			}
		</>
	)
}

export default Chat;