import {useDispatch, useSelector} from "react-redux";
import {
	deleteBillingPayment,
	deleteBillingSubscriptionPlanID,
	fetchBillingAddress,
	fetchBillingInvoices,
	fetchBillingSubscriptionPlanID,
	fetchSubscriptionData,
	postBillingAddress,
	postBillingPayment,
	setLoadings,
	setSubscriptionData
} from "../../redux/slices/paymentSlice";

const usePayment = () => {

	const dispatch = useDispatch();

	const { subscriptionData, loadings, addressData, invoicesData } = useSelector((state) => state.payment);
	const handleSetSubscriptionData = (subscription) => dispatch(setSubscriptionData(subscription))

	const handleFetchSubscriptionData = () => dispatch(fetchSubscriptionData());

	const handlePostBillingPayment = ({paymentMethodId, subscriptionId}) => {
		return dispatch(postBillingPayment({paymentMethodId, subscriptionId}))
	}

	const handleFetchBillingAddress = () => dispatch(fetchBillingAddress());

	const handlePostBillingAddress = (data) => dispatch(postBillingAddress(data));

	const handleFetchBillingInvoices = () => dispatch(fetchBillingInvoices());

	const handleSetLoadings = (key, value) => dispatch(setLoadings({ field: key, value: value }))

	const handleFetchBillingSubscriptionPlanID = (planID) => dispatch(fetchBillingSubscriptionPlanID(planID));

	const handleDeleteBillingSubscriptionPlanID = (planID) => dispatch(deleteBillingSubscriptionPlanID(planID))

	return{
		subscriptionData,
		loadings,
		addressData,
		invoicesData,
		handleSetLoadings,
		handleSetSubscriptionData,
		handleFetchSubscriptionData,
		handlePostBillingPayment,
		handleFetchBillingAddress,
		handlePostBillingAddress,
		handleFetchBillingInvoices,
		handleFetchBillingSubscriptionPlanID,
		handleDeleteBillingSubscriptionPlanID
	}
}

export default usePayment;