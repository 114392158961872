import React from "react";
import {useFormikContext} from 'formik';
import useApi from "../../../../../Hooks/useApi";
import useUser from "../../../../../Hooks/ReduxHooks/useUser";
import DisplayRepeater from "../../../Common/DisplayRepeater";
import useConfig from "../../../../../Hooks/ReduxHooks/useConfig";

const FreeRepeater = ({ title, section }) => {

	const { values, setFieldValue } = useFormikContext();

	const { lang } = useUser();

	const { request } = useApi();

	const {config} = useConfig();

	const fetchHints = async (query) => {

		const hints = config[section].items;

		if (hints.length !== 0) {

			return hints.filter(item => item.toLowerCase().includes(query.toLowerCase())).map(item => ({
				id: section,
				name: item
			})).slice(0, 10);

		}

		return [];
	};

	const assignHint = (item, finished) => {
		setFieldValue(section, values[section].concat(item.name));
		finished();
	};

	const parseItems = () => {
		let items = [];
		if (Array.isArray(values[section]) && values[section].length > 0) {
			values[section].forEach((name, id) => {
				items.push({
					id: id,
					name: typeof name === 'object' ? name.language : name,
					level: name.level || ""
				});
			});
		}
		return items;
	};

	const handleRemove = (item, finished) => {
		setFieldValue(section, values[section].filter((name) => (typeof name === 'object' ? name.language : name) !== item.name));
		finished();
	};

	return (
		<>
			<DisplayRepeater assignHint={assignHint} fetchHints={fetchHints} handleRemove={handleRemove} isBlocked={false} items={parseItems()} title={title} section={section} />
		</>
	);
};

export default FreeRepeater;
