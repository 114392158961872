import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import BottomBar from '../../Layout/Footer/BottomBar';
import useUser from '../../../Hooks/ReduxHooks/useUser';

const Footer = () => {

	const {isMobile} = useUser();

	const {t} = useTranslation();
	const currentYear = new Date().getFullYear();
	const LinkFooter = ({href, text}) => {
		return (
			<li className={'d-flex'}>
				<Link className={'font-md font-family-open-sans'} to={href}>{text}</Link>
			</li>
		);
	};

	return (
		<>
			{isMobile && <BottomBar/>}
			<div className={'page-footer pb-lg-0 pb-5'}>
				<div className="page-footer-menu">
					<ul className="d-flex ps-0">
						<LinkFooter href={'https://site.jobmultiverse.com/privacy-policy/'} text={'Privacy Policy'}/>
						<LinkFooter href={"https://site.jobmultiverse.com/terms/"} text={'Terms of Service'}/>
						<LinkFooter href={"https://site.jobmultiverse.com/bug-report/"} text={'Bug Report'}/>
					</ul>
				</div>
				<div className="page-footer-copy font-lg font-family-open-sans">
					Copyright &copy; {currentYear}. jobmultiverse.com
				</div>
			</div>
		</>
	)

};

export default Footer;