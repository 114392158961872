import {useLocation} from "react-router";
import {useEffect} from "react";

const GoToTop = () => {
	const routePath = useLocation();
	const onTop = () => {
		window.scrollTo(0, 0);
	}
	useEffect(() => {
		onTop()
	}, [routePath]);
	return null;
}

export default GoToTop;