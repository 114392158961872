import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import StripeCheckout from "./StripeCheckout";
import StripeCompleted from "./StripeCompleted";
import stripeConfig from "../../../../Config/stripeConfig";

const StripeBlock = ({subscriptionData}) => {

	const {options, stripePromise} = stripeConfig(subscriptionData);
	const [collectPayment, setCollectPayment] = useState(false);

	useEffect(() => {
		if (subscriptionData?.latest_invoice?.payment_intent?.status === "succeeded") {
			setCollectPayment(false);
		} else {
			setCollectPayment(true);
		}
	})

	return (
		<>
			<Row>
				<Col sm={12} lg={6} className={'stripe-block-border pb-lg-0 mb-lg-0 pb-3 mb-3'}>
					{(collectPayment || !subscriptionData.payment_method.card) && <Elements stripe={stripePromise} options={options}>
						<StripeCheckout />
					</Elements>}
					{(!collectPayment && subscriptionData.payment_method.card) &&
						<Elements stripe={stripePromise} options={options}>
							<StripeCompleted subscriptionData={subscriptionData}/>
						</Elements>
					}
				</Col>
				<Col sm={12} lg={6}>
					<p className={'font-lg'}>
						To get access to Premium Features, you need to add a basic payment method. Payment is collected at the beginning
						of
						each billing period from the day of the first transaction. You can cancel the subscription at any time by
						removing your card. Your account will remain active, but you will lose access to premium features. However,
						other users will still be able to find you through our search function, and you will still have the option to
						share your profile via the share feature.
					</p>
				</Col>
			</Row>
		</>
	)
}

export default StripeBlock;