import TopBar from "../TopBar";
import './Assets/main.css';
import {Container} from "react-bootstrap";
import React from "react";
import {Page} from "../../Layout/Page";
import TabPaymentData from "./Tabs/TabPaymentData";
import TabBillingHistory from "./Tabs/TabBillingHistory";
import TabsComponent from "../../Parts/TabsComponent";

const Index = () => {

	const Configuration = [
		{
			key: 'payment-data',
			section_id: 'payment-data',
			title: 'Payment Data',
			components: <TabPaymentData/>
		},
		{
			key: 'billing-history',
			section_id: 'billing-history',
			title: 'Billing History',
			components: <TabBillingHistory/>
		}
	];

	return (
		<Page title='Payment and Billing'>
			<TopBar title='Payment and Billing' desc='payment desc' backButton={true}/>
			<Container>
				<div className={'billing'}>
					<TabsComponent configuration={Configuration} sectionDefault={'payment-data'} />
				</div>
			</Container>
		</Page>
	);
}

export default Index;