import React, {useEffect, useRef, useState} from "react";
import './Browse.scss';
import {Container} from 'react-bootstrap';
import {Page} from '../Layout/Page';
import Map from './Parts/Map';
import BrowseHeader from './BrowseHeader';
import {useInfiniteHits, useInstantSearch} from 'react-instantsearch';
import Item from './Item/Item';
import SkeletonLoop from '../Browse/Skeleton/SkeletonLoop';
import NoResultsIcon from '../../assets/svg/icon-no-results.svg';
import {ClearFilters} from './Filters/FiltersParts';
import SliderPopup from './SliderPopup/SliderPopup';
import useUser from '../../Hooks/ReduxHooks/useUser';
import {useSearchParams} from "react-router-dom";
import Related from "./Related";

const NoResults = () => {
	return (
		<div className={'d-flex justify-content-center flex-column'}>
			<div className={'text-center mb-3 mt-3'}>
				<img src={NoResultsIcon} alt={'No results found'} className={'img-fluid'} style={{maxWidth: '200px'}}/>
			</div>
			<div className={'d-flex justify-content-center mb-3'}>
				<ClearFilters/>
			</div>
			<div className={'text-center'}>
				<h4>We couldn&apos;t find any records listings that match your selected criteria.</h4>
				<p>Check for any typos in the phrases you entered or try a more general query.</p>
			</div>
		</div>
	)
}

const DisplayItems = ({hits, setIndex, showMore, isLastPage}) => {

	/**
	 *
	 * Handle click on item to open popup
	 * @param item
	 *
	 */

	const {setIndexUiState} = useInstantSearch();

	const {user} = useUser();

	const sentinelRef = useRef(null);

	useEffect(() => {

		if(sentinelRef.current !== null) {
			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if(entry.isIntersecting && !isLastPage) {
						showMore();
					}
				});
			});
			observer.observe(sentinelRef.current);
			return () => {
				observer.disconnect();
			};
		}
	}, [isLastPage, showMore]);

	const handleClick = (item) => {

		const findIndex = hits.findIndex(hit => hit.uuid === item.uuid);

		if(findIndex !== -1) {

			setIndex(findIndex);

		}

		else {

			alert('unable to open popup, cannot find index');

		}

	};

	const parsedHits = user ? hits.filter(hit => user.hidden_profiles.includes(hit.uuid) === false) : hits;

	return (
		<div className="app-browse-loop">
			{parsedHits.map((hit, index) => <Item key={index} item={hit} handleClick={handleClick} setIndexUiState={setIndexUiState}/>)}
			<div ref={sentinelRef} aria-hidden="true"></div>
		</div>
	)
}

export const BrowseInfiniteHitsLoader = ({loading = true}) => {

	return (
		<SkeletonLoop count={20} loading={loading}/>
	)

}

export const BrowseInfiniteHits = (props) => {

	const {showSlider = true} = props;

	const [pristine, setPristine] = useState(true);

	const {hits, isLastPage, showMore} = useInfiniteHits(props);

	const {status} = useInstantSearch();

	const [index, setIndex] = useState(-1);

	/**
	 * Infinite scroll
	 */

	useEffect(() => {

		setPristine(false);

	}, [])

	if(pristine || (hits.length === 0 && status === 'loading')) {

		return (
			<SkeletonLoop count={20} loading={true}/>
		)

	}

	/**
	 * No results
	 */

	if(hits.length === 0) {

		return <NoResults/>

	}

	return (
		<>
			<DisplayItems hits={hits} setIndex={setIndex} showMore={showMore} isLastPage={isLastPage}/>
			<SkeletonLoop count={20} loading={status !== 'idle'}/>
			{showSlider && index !== -1 && <SliderPopup index={index} setIndex={setIndex} showMore={showMore} total={hits.length} items={hits}/>}
		</>
	);

};

const BrowseSimilar = ({id}) => {

	return (
		<Related similarID={id}/>
	)
}

const BrowseRecords = () => {

	const [ready, setReady] = useState(false);

	const {setIndexUiState} = useInstantSearch();

	useEffect(() => {

		setIndexUiState((prev) => {
			return {
				...prev,
				configure: {
					filters: ''
				}
			}
		});

		setReady(true);

	}, [])

	return ready ? <BrowseInfiniteHits/> : <BrowseInfiniteHitsLoader/>

}

const Browse = () => {

	const [mapEnabled] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();

	const similarID = searchParams.get("similar")

	return (
		<Page title={`Find your worker`}>
			<div className={`app-browse main-page`}>
				<Container>
					<div className={'app-browse-inner'}>
						<BrowseHeader setMapEnabled={false}/>
						{similarID ? <BrowseSimilar id={similarID}/> : <BrowseRecords/>}
					</div>
				</Container>
				{mapEnabled && <Map/>}
			</div>
		</Page>
	);

};

export default Browse;
