import { FaDownload } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import usePopup from "../../../../Hooks/ReduxHooks/usePopup";
import CloseOutside from "../../../Parts/CloseOutside";
import CloseIcon from "../../../Parts/CloseIcon";
import {Form} from "react-bootstrap";
import ProfileError from "../../Edit/Parts/ProfileError";
import React, {useState} from "react";
import {Formik} from "formik";
import * as Yup from "yup";

const schema = {
	email: Yup.string().email().required('Address is required'),
};

const SendToPopup = () => {

	const {closePopup} = usePopup();

	const [invoiceEmails, setInvoiceEmails] = useState(localStorage.getItem('invoice-emails') ? JSON.parse(localStorage.getItem('invoice-emails')) : []);

	const initialData = {
		email: ''
	}

	const sendInvoice = (values) => {
		let updatedInvoiceEmails = [...invoiceEmails];

		const emailIndex = updatedInvoiceEmails.findIndex(email => email === values.email);

		if (emailIndex !== -1) {
			updatedInvoiceEmails.splice(emailIndex, 1);
		}

		updatedInvoiceEmails = [values.email, ...updatedInvoiceEmails];

		if (updatedInvoiceEmails.length > 3) {
			updatedInvoiceEmails = updatedInvoiceEmails.slice(0, 3);
		}

		setInvoiceEmails(updatedInvoiceEmails);

		localStorage.setItem('invoice-emails', JSON.stringify(updatedInvoiceEmails)); // Zapisz w localStorage
	};

	return(
		<CloseOutside handleClose={closePopup}>
			<div className={'bg-white position-relative p-5 rounded-4'}>
				<CloseIcon additionalStyles={'mt-3 me-3'} handleClose={closePopup} />
				<div className={''}>
					<h4>Send Invoice to</h4>
					<Formik validateOnChange={false} validateOnBlur={false} initialValues={initialData} onSubmit={(values) => sendInvoice(values)} validationSchema={Yup.object().shape(schema)}>
						{({handleSubmit, handleChange, handleBlur, values, setFieldValue, errors}) => (
							<form onSubmit={handleSubmit} className={'mt-3'}>
								{invoiceEmails.length !== 0 &&
									<div className={'mb-3 w-100'}>
										<span className={'text-gray font-md'}>Last email seneded:</span>
										{invoiceEmails.map((email, index) => (
											<button onClick={() => setFieldValue('email', email)} key={index} className="border-0 btn p-0 font-md ms-2 text-primary" type={'button'}>
												{email}
											</button>
										))}
									</div>
								}
								<div className={'d-flex align-items-end'}>
									<Form.Group className="position-relative form-group w-100">
										<Form.Label>E-mail</Form.Label>
										<Form.Control placeholder={'Enter email address'} className={`${errors.email ? 'border border-danger' : ''}`} onChange={handleChange} onBlur={handleBlur} value={values.email} name={'email'} />
										<ProfileError profileError={errors.email} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'}/>
									</Form.Group>
									<button type={'submit'} className={'btn btn-primary ms-2 h-100'}>
										Send
									</button>
								</div>
							</form>
						)}
					</Formik>

				</div>
			</div>
		</CloseOutside>
	)
}

const InvoiceActions = ({pdfLink}) => {

	const {openPopup} = usePopup();

	return(
		<div className={'d-flex'}>
			<a href={pdfLink} className={'bg-transparent border-0 text-primary d-flex align-items-center'}>
				<FaDownload className={'me-1'} /> Download PDF
			</a>
			{/*<button onClick={() => openPopup(SendToPopup)} className={'bg-transparent border-0 text-primary d-flex align-items-center'}>*/}
			{/*	<MdOutlineEmail size={16} className={'me-1'} /> Send to*/}
			{/*</button>*/}
		</div>
	)
}

export default InvoiceActions;