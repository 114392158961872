import React, {useState} from 'react';
import CloseOutside from '../../../Parts/CloseOutside';
import Config from '../../../../Config/AppConfig';
import AnimatedDropdown, {AnimatedDropdownList, AnimatedDropdownListItem} from '../../../Parts/AnimatedDropdown';
import useUser from "../../../../Hooks/ReduxHooks/useUser";

const getRanges = (key = null) => {

	if(key !== null) {

		return Config.ranges[key];

	}

	return Config.ranges;

};

const LocationRadius = ({className}) => {

	const [open, setOpen] = useState(false);

	const {geo, handleSetGeo} = useUser();

	const handleClickCurrentRange = (e) => {

		e.preventDefault();

		setOpen(!open);

	};

	const handleClickRange = (radius) => {

		setOpen(false);

		handleSetGeo({...geo, radius: radius});

	};

	const mappedRadius = Object.keys(getRanges());

	if(geo.lat == null) {

		return null;

	}

	return (
		<CloseOutside handleClose={() => setOpen(false)} className={`search-location-radius ${className} ${open ? 'active' : ''}`}>
			<button onClick={handleClickCurrentRange}>
				<span>+ {geo.radius} miles</span>
			</button>
			<AnimatedDropdown isOpen={open} className={'search-location-radius-options'}>
				<AnimatedDropdownList>
					{mappedRadius.map((radius, index) => <AnimatedDropdownListItem key={index} handleClick={() => handleClickRange(radius)}>{radius} {radius === '1' ? 'mile' : 'miles'}</AnimatedDropdownListItem>)}
				</AnimatedDropdownList>
			</AnimatedDropdown>
		</CloseOutside>
	);

};

export default LocationRadius;
