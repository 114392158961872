import {Slugify} from "./AppUtils";

const itemLink = (withHost = false, id = null, name = null) => {

	const host = withHost ? window.location.origin : '';

	const slug = Slugify(name || 'profile');

	return `${host}/profile/${id}/${slug}`;

};

export default itemLink;