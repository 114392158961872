import React, {useEffect} from "react";
import {Alert, Col, Form, Row} from 'react-bootstrap';
import {Formik} from "formik";
import {Loader} from "../../../Parts/Loaders";
import StripeBlock from "../Parts/StripeBlock";
import * as Yup from "yup";
import ProfileError from "../../Edit/Parts/ProfileError";
import {Rings} from "react-loader-spinner";
import usePayment from "../../../../Hooks/ReduxHooks/usePayment";
import usePopup from "../../../../Hooks/ReduxHooks/usePopup";
import ConfirmationPopup from "../../../Parts/ConfirmationPopup";

const MONTHLY_KEY = process.env.REACT_APP_STRIPE_MONTHLY
const ANNUAL_KEY = process.env.REACT_APP_STRIPE_ANNUAL

const TabPaymentData = () => {

	const {
		subscriptionData,
		handlePostBillingAddress,
		handleSetLoadings,
		loadings,
		addressData,
		handleFetchBillingAddress ,
		handleFetchBillingSubscriptionPlanID,
		handleFetchSubscriptionData,
		handleDeleteBillingSubscriptionPlanID
	} = usePayment();

	const {openPopup, closePopup} = usePopup();

	const handleSaveAddress = async (values) => {

		await handlePostBillingAddress(values)
		await handleFetchSubscriptionData();

	};

	useEffect(() => {
		const fetchAddress = async () => {
			await handleFetchBillingAddress()

			await handleFetchSubscriptionData();
		}

		if(!addressData){
			fetchAddress();
		}
	}, []);

	const handleSubmit = async (values) => {

		const newAddress = {
			"address": {
				"line1": values.line1,
				"line2": values.line2,
				"city": values.city,
				"state": values.state,
				"zipcode": values.zipcode,
				"country": "us"
			}
		}

		await handleSaveAddress(newAddress);
	};

	const initial = {
		line1: addressData ? addressData['address']['line1'] : '',
		line2: addressData ? addressData['address']['line2'] : '',
		city: addressData ? addressData['address']['city'] : '',
		state: addressData ? addressData['address']['state'] : '',
		zipcode	: addressData ? addressData['address']['zipcode'] : '',
	}

	const Schema = {
		line1: Yup.string().required('Address is required'),
		city: Yup.string().required('City is required'),
		zipcode: Yup.string().required('Zip code is required'),
		state: Yup.string().required('State is required'),
	};

	const choosePlanID = async (planID) => {
		await handleFetchBillingSubscriptionPlanID(planID)
	}

	const confirmChange = () => {
		const confirmationProps = {
			title: 'Cancel Subscription',
			text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris scelerisque id leo id semper. Phasellus facilisis vulputate dolor, et iaculis nunc sollicitudin eget.',
			confirmLoading: loadings.deleteBillingSubscriptionPlanID,
			callback: async () => {
				await handleDeleteBillingSubscriptionPlanID(subscriptionData.id)
				await handleFetchSubscriptionData();
				closePopup()
			}
		}
		openPopup(ConfirmationPopup, confirmationProps)
	}

	const formatPrice = (price) => {
		return (price / 100).toFixed(2);
	}

	return (
		<>
			<div>
				<div className="d-flex align-items-center mt-4 mb-2 position-relative">
					<h4 className="m-0 title">Subscription type</h4>
				</div>
				{loadings.subscriptionData ?
					<Loader />
					:
					<>
						{addressData ?
							<>
								{subscriptionData ?
									<div>
										<p className={'mb-0'}>
											{subscriptionData.plan.interval === 'year' ? 'Annual ' : 'Monthly '}
											subscription is currently selected
											${formatPrice((subscriptionData.plan.amount))}/{subscriptionData.plan.interval}
										</p>
										<button type={'button'} className={'btn btn-primary mt-3'} onClick={confirmChange}>
											Cancel Subscription
										</button>
									</div>
									:
									<div>
										<p className={'mb-0'}>
											Choose your plan
										</p>
										<div className={'mt-2'}>
											<button className={'btn btn-primary'} onClick={() => choosePlanID(MONTHLY_KEY)}>
												{/*TODO*/}
												{/*Connect prices from config*/}
												Monthly 29.99/month
											</button>
											<button className={'btn btn-primary'} onClick={() => choosePlanID(ANNUAL_KEY)}>
												{/*TODO*/}
												{/*Connect prices from config*/}
												Annual $299.90/year
											</button>
										</div>
									</div>
								}
							</>
							:
							<Alert className={'font-family-open-sans'} variant={'info'}>You need to add billing address first!</Alert>
						}
					</>
				}
			</div>
			<div className="d-flex align-items-center mt-4 mb-2 position-relative">
				<h4 className="m-0 title">Payment method</h4>
			</div>
			{loadings.subscriptionData ?
				<Loader />
				:
				<>
					{(addressData) ?
						<>
							{subscriptionData ?
								<StripeBlock subscriptionData={subscriptionData}/>
								:
								<Alert className={'font-family-open-sans'} variant={'info'}>You must select a subscription type</Alert>
							}
						</>
						:
						<Alert className={'font-family-open-sans'} variant={'info'}>You need to add billing address first!</Alert>
					}
				</>
			}
			<div className="d-flex align-items-center mt-4 mb-2 position-relative">
				<h4 className="m-0 title">Billing information</h4>
			</div>
			{loadings.fetchBillingAddress ?
				<Loader/>
				:
				<Formik validateOnChange={false} validateOnBlur={false} initialValues={initial} onSubmit={handleSubmit} validationSchema={Yup.object().shape(Schema)}>
					{({handleSubmit, values, handleBlur, handleChange, errors, touched}) => (
						<Form onSubmit={handleSubmit} className="auth-form mb-5">
							<div className="d-flex flex-wrap">
								<Row className={'w-100'}>
									<Col sm={12} lg={6}>
										<Form.Group id="line1" className="my-3 position-relative form-group">
											<Form.Label>Address line 1</Form.Label>
											<Form.Control className={`${errors.line1 ? 'border border-danger' : ''}`} type="text" name="line1" value={values.line1} placeholder="Address line 1" onChange={handleChange} onBlur={handleBlur}/>
											<ProfileError profileError={errors.line1} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'}/>
										</Form.Group>
									</Col>
									<Col sm={12} lg={6}>
										<Form.Group id="line2" className="my-3 position-relative form-group">
											<Form.Label>Address line 2</Form.Label>
											<Form.Control className={`${errors.line2 ? 'border border-danger' : ''}`} type="text" name="line2" value={values.line2} placeholder="Address line 2" onChange={handleChange} onBlur={handleBlur}/>
											<ProfileError profileError={errors.line2} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'}/>
										</Form.Group>
									</Col>
								</Row>
								<Row className={'w-100'}>
									<Col sm={12} lg={4}>
										<Form.Group id="city" className="my-3 position-relative form-group">
											<Form.Label>City</Form.Label>
											<Form.Control className={`${errors.city ? 'border border-danger' : ''}`} type="text" name="city" value={values.city} placeholder="City" onChange={handleChange} onBlur={handleBlur}/>
											<ProfileError profileError={errors.city} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'}/>
										</Form.Group>
									</Col>
									<Col sm={12} lg={4}>
										<Form.Group id="state" className="my-3 position-relative form-group">
											<Form.Label>State</Form.Label>
											<Form.Control className={`${errors.state ? 'border border-danger' : ''}`} type="text" name="state" value={values.state} placeholder="State" onChange={handleChange} onBlur={handleBlur}/>
											<ProfileError profileError={errors.state} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'}/>
										</Form.Group>
									</Col>
									<Col sm={12} lg={4}>
										<Form.Group id="zipcode" className="my-3 position-relative form-group">
											<Form.Label>Zip code</Form.Label>
											<Form.Control className={`${errors.zipcode ? 'border border-danger' : ''}`} type="text" name="zipcode" value={values.zipcode} placeholder="Zip code" onChange={handleChange} onBlur={handleBlur}/>
											<ProfileError profileError={errors.zipcode} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'}/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={12} lg={12}>
										<button disabled={loadings.postBillingAddress} className="btn btn-primary mt-3 font-lg d-flex justify-content-center" type={'submit'}>
											{!loadings.postBillingAddress && <>{addressData ? "Update" : 'Save'} Billing Address</>}
											{loadings.postBillingAddress && <Rings height="28" width="28" color="#fff" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
										</button>
									</Col>
								</Row>
							</div>
						</Form>
					)}
				</Formik>
			}
		</>
	);

};
export default TabPaymentData;
