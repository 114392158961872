import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import * as Sentry from "@sentry/react";
import './i18n';
import App from './App';
import store from "./redux/store";
import {Provider} from "react-redux";

if(process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: [process.env.REACT_APP_URL],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	});
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<App/>
	</Provider>
);