import galleryIcon from '../../../assets/svg/premium/gallery.svg';
import tagsIcon from '../../../assets/svg/premium/tags.svg';
import thumbnailIcon from '../../../assets/svg/premium/thumbnail.svg';
import chatIcon from '../../../assets/svg/premium/chat.svg';
import categoriesIcon from '../../../assets/svg/premium/categories.svg';
import linkIcon from '../../../assets/svg/premium/link.svg';
import i18n from 'i18next';
import premiumTranslations from '../Translations/en/premiumTranslations.json'
import {useTranslation} from "react-i18next";
const PremiumFeatures = () => {

	i18n.addResources('en', 'premium', premiumTranslations.premiumFeatures);

	const { t } = useTranslation('premium');

	const featuresConfiguration = [
		{
			icon: galleryIcon,
			text: t('features.0')
		},
		{
			icon: tagsIcon,
			text: t('features.1')
		},
		{
			icon: thumbnailIcon,
			text: t('features.2')
		},
		{
			icon: chatIcon,
			text: t('features.3')
		},
		{
			icon: categoriesIcon,
			text: t('features.4')
		},
		{
			icon: linkIcon,
			text: t('features.5')
		},
	]

	const FeaturesLoop = () => {
		return(
			<ul className="list-unstyled features-loop mt-4">
				{featuresConfiguration.map((featureItem, index) => {
					return(
						<li className="d-flex align-items-center mb-2" key={index}>
							<img src={featureItem.icon} alt={featureItem.text} />
							<p className="mb-0 ms-2 font-family-open-sans">{featureItem.text}</p>
						</li>
					)
				})}
			</ul>
		)
	}

	return(
		<div className="premium-features">
			<h1 className="font-weight-md">
				{ t('title')}
			</h1>
			<p className="font-xl font-family-open-sans">
				{ t('description')}
			</p>
			<div className="d-flex align-items-center">
				<h3 className="font-xl font-weight-md text-nowrap mb-0 me-4 text-uppercase">{ t('includedTitle')}</h3>
				<span className="line w-100 bg-primary"></span>
			</div>
			<FeaturesLoop />
		</div>
	)
}

export default PremiumFeatures;