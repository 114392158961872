import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Auth from "../../Auth/Auth";
import useUser from "../../../Hooks/ReduxHooks/useUser";
import { CiChat1, CiGrid41, CiStar, CiUser } from "react-icons/ci";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";
import AnimatedDropdown from "../../Parts/AnimatedDropdown";
import avatarPlaceholder from "../../../assets/images/avatar_placeholder.png";
import useMessage from "../../../Hooks/ReduxHooks/useMessage";

const GuestPanel = () => {
  const { openPopup } = usePopup();

  return (
    <>
      <button className={"guest-panel sign"} onClick={() => openPopup(Auth)}>
        SIGN IN
      </button>
      <button
        className={"guest-panel join"}
        onClick={() => openPopup(Auth, { type: "register" })}
      >
        JOIN
      </button>
    </>
  );
};

const Avatar = ({ user }) => {
  if (!user.avatar) {
    return (
      <img
        src={avatarPlaceholder}
        width={50}
        height={50}
        className="rounded-5 object-fit-cover"
        alt={user.name}
      />
    );
  }

  return (
    <img
      src={user.avatar}
      width={50}
      height={50}
      className="rounded-5 object-fit-cover"
      alt={user.name}
    />
  );
};

const UserPanel = () => {
  const { t } = useTranslation();

  const { user, handleLogout } = useUser();

  const [open, setOpen] = useState(false);

  const buyerConfig = [
    {
      linkText: t("parts.header.topBar.buttons.profile.menu.settings"),
      linkValue: "account/settings",
    },
  ];

  const sellerConfig = [
    {
      linkText: t("parts.header.topBar.buttons.profile.menu.edit"),
      linkValue: "account/edit",
    },
    {
      linkText: t(
        "parts.header.topBar.buttons.profile.menu.invoicesAndPayments",
      ),
      linkValue: "account/billing",
    },
    {
      linkText: t("parts.header.topBar.buttons.profile.menu.settings"),
      linkValue: "account/settings",
    },
  ];

  return (
    <div
      className="user-panel"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Link to={"/account"} className="user-panel-link">
        <Avatar user={user} />
      </Link>
      <AnimatedDropdown isOpen={open} className={"dropdown-user-panel"}>
        <h5>
          Hello <strong>{user.name}</strong>
        </h5>
        {(user.account_type === "provider" ? sellerConfig : buyerConfig).map(
          (item, index) => {
            return (
              <div className="account-link" key={index}>
                <Link className="link" to={item.linkValue}>
                  {item.linkText}
                </Link>
              </div>
            );
          },
        )}
        {user.account_type === "searcher" && (
          <Link to="/account/settings" className="btn btn-primary mt-2">
            Become a Seller
          </Link>
        )}
        <button
          className="btn btn-outline-primary text-nowrap ps-4 pe-4 pt-2 pb-2 mt-2"
          onClick={handleLogout}
        >
          {t("parts.header.topBar.logout")}
        </button>
      </AnimatedDropdown>
    </div>
  );
};

const Navigation = () => {
  const { t } = useTranslation();

  const { user } = useUser();

  const { openPopup } = usePopup();

  const { unreadCount } = useMessage();

  const navigate = useNavigate();

  const Links = [
    {
      key: "browse",
      icon: <CiGrid41 style={{ fontSize: "32px" }} />,
      text: t("parts.header.topBar.buttons.browse.title"),
      link: "/",
    },
    {
      key: "favorites",
      icon: <CiStar style={{ fontSize: "32px" }} />,
      text: "Favorites",
      link: "/observations",
    },
    {
      key: "messages",
      icon: <CiChat1 style={{ fontSize: "32px" }} />,
      text: t("parts.header.topBar.buttons.messages.title"),
      link: "/messages",
    },
  ];

  const handleChangeRouting = (link, key) => {
    if (!user && key !== "browse") {
      return openPopup(Auth, { type: "login" });
    }
    navigate(link);
  };

  return (
    <div className="top-navigation">
      {Links.map((el, index) => (
        <button
          onClick={() => handleChangeRouting(el.link, el.key)}
          key={index}
          className="top-bar-link"
        >
          {el.icon}
          {el.key === "messages" && unreadCount !== 0 && (
            <span className="new-message-dot"></span>
          )}
          <span>{el.text}</span>
        </button>
      ))}
      {user ? <UserPanel /> : <GuestPanel />}
    </div>
  );
};

export default Navigation;
