import {Link} from "react-router-dom";
import React from "react";
import {ReactComponent as WhiteIcon} from "../../../assets/svg/premium/thumbnail-white.svg";

const PremiumBadge = ({planID, additionalClass, marginRight}) => {
	if(planID !== 'premium') {
		return
	}
	return(
		<div style={{marginRight: `${marginRight}rem`}} className={`bg-primary position-absolute z-3 end-0 top-0 pb-2 ps-2 pe-2 pt-2 ${additionalClass ? additionalClass : ''}`}>
			<WhiteIcon width={22} />
		</div>
	)
}

export default PremiumBadge;