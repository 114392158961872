import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import usePopup from "../../Hooks/ReduxHooks/usePopup";
import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';
import {useTranslation} from 'react-i18next';
import {AlertCircle} from 'react-feather';
import useUser from "../../Hooks/ReduxHooks/useUser";

const Default = () => {

	const {popupContent, popupProps, popupsContent} = usePopup()

	const {isMobile} = useUser();

	const ComponentToRender = popupContent;

	useEffect(() => {
		popupContent ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'scroll';
	}, [popupContent]);

	return (
		<>
			{popupContent &&
				<div className="app-popup visible">
					<ComponentToRender {...popupProps}/>
				</div>
			}
			{(popupsContent.length !== 0 && isMobile) &&
				<>
					{popupsContent.map((el, index) => {
						el.visible ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'scroll';
						const ComponentToRender = el.component
						return(
							<div key={index} className={`app-popup ${el.visible ? 'visible' : 'hidden'}`}>
								<ComponentToRender {...popupProps}/>
							</div>
						)
					})}
				</>
			}
			<Header/>
			<Outlet/>
			<Footer/>
		</>
	);
};

const Offline = ({Message, Refresh}) => {

	const {t} = useTranslation();

	return (
		<div className={"main-error"}>
			<AlertCircle size={100}/>
			<h3>Error</h3>
			<p>
				{Message}
			</p>
			<button className={'btn btn-outline-secondary'} onClick={Refresh}>{t('main.offline.tryAgain')}</button>
		</div>
	);

}

export {
	Default,
	Offline
};
