import {Info} from 'react-feather';
import {Overlay, Tooltip} from 'react-bootstrap';
import React, {useRef, useState} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {useTranslation} from 'react-i18next';

export const TooltipHelper = ({ children, className, icon: Icon = Info, ...props }) => {

	const [show, setShow] = useState(false);

	const renderTooltip = (props, children) => {

		return(
			<Tooltip id="button-tooltip" {...props}>
				{children}
			</Tooltip>
		)
	}

	return(
		<div className={`tooltip-explanation ${className}`} {...props} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
			<OverlayTrigger show={show} trigger={['hover', 'focus', 'click']} placement="top" overlay={(props) => renderTooltip(props, children)} delay={{ show: 100, hide: 100 }}>
				<div className={'d-flex'}>
					<Icon className="tooltip-explanation-icon" />
				</div>
			</OverlayTrigger>
		</div>
	)
}

export const TooltipBadge = ({children, length = 0}) => {

	const {t} = useTranslation();

	const [showTooltip, setShowTooltip] = useState(false);

	const target = useRef(null);

	const tooltipRef = useRef(null);

	const timeoutId = useRef();

	const handleMouseEnter = () => {

		clearTimeout(timeoutId.current);

		setShowTooltip(true);

	};

	const handleMouseLeave = () => {

		timeoutId.current = setTimeout(() => {
			if(!tooltipRef.current || !tooltipRef.current.contains(document.activeElement)) {
				setShowTooltip(false);
			}
		}, 350);

	};

	return (
		<>
			<p className="mb-0 tooltip-item font-family-open-sans" ref={target} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				{length === 1 ? `+ 1 ${t('jobs.browse.parts.tooltipItem.other')}` : `+ ${length} ${t('jobs.browse.parts.tooltipItem.others')}`}
			</p>
			<Overlay target={target.current} show={showTooltip} placement="top">
				<Tooltip id="button-tooltip" ref={tooltipRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
					{children}
				</Tooltip>
			</Overlay>
		</>
	);
};
