import React from "react";
import useItem from '../../../Hooks/useItem';
import {CiLocationOn} from "react-icons/ci";
import useConfig from '../../../Hooks/ReduxHooks/useConfig';
import {FaDollarSign, FaSuitcase, FaFolder} from 'react-icons/fa';
import {TooltipBadge} from '../../Parts/Tooltips';

const DisplayCategories = ({categories, setCategory}) => {

	const {config} = useConfig();

	if (!categories || categories.length === 0) return

	const first = categories[0];

	const configCategory = config.categories.items.find(cat => cat.slug === first);

	return (
		<div className={"group cat"}>
			<span className={'select'} onClick={() => setCategory(first)}>
				<FaSuitcase size={18}/>
				{configCategory.title}
			</span>
			<DisplayMoreCategories items={categories.slice(1)} setCategory={setCategory}/>
		</div>
	)
}

const DisplayMoreCategoriesItem = ({item, index, items, setCategory}) => {

	const {config} = useConfig();

	const configCategory = config.categories.items.find(cat => cat.slug === item);

	return (<span className={'select cursor-pointer'} onClick={() => setCategory(item)}>
				{configCategory.title} {items.length - 1 !== index && <>, <space/></>}
			</span>);

}

const DisplayMoreCategories = ({items, setCategory}) => {

	if (items.length === 0) return null;

	return (<div className={'more-items'}>
			<TooltipBadge length={items.length}>
				{items.map((item, index) => <DisplayMoreCategoriesItem item={item} key={index} index={index} items={items} setCategory={setCategory}/>)}
			</TooltipBadge>
		</div>)

}

const DisplaySalary = ({item}) => {

	const type = parseInt(item.salary_type);

	const {salary_from = 0, salary_to = 0} = item;

	if (type === null || salary_from === null || salary_to === null || salary_from.length === 0 || salary_to.length === 0) {
		return null;
	}

	return (<div className={'group'}>
			<FaDollarSign size={16}/>${parseInt(salary_from)}-${parseInt(salary_to)} / h
		</div>)

};

const DisplayTags = ({tags, setTag}) => {

	if (!tags || tags.length === 0) return

	return (<div className={"group"}>
			<span className={'select text-limit'} onClick={() => setTag(tags[0])}>
				<FaFolder size={18}/>
				{tags[0]}
			</span>
			<DisplayMoreTags items={tags.slice(1)} setTag={setTag}/>
		</div>)
}

const DisplayMoreTagsItem = ({items, item, index, setTag}) => {

	return (<span className={'select cursor-pointer'} onClick={() => setTag(item)}>
				{item} {items.length - 1 !== index && <>, <space/></>}
			</span>);

}

const DisplayMoreTags = ({items, setTag}) => {

	if (items.length === 0) return null;

	const handleClick = (item) => {

		setTag(item);

	}

	return (
		<div className={'more-items'}>
			<TooltipBadge length={items.length}>
				{items.map((item, index) => <DisplayMoreTagsItem key={index} items={items} item={item} index={index} setTag={setTag}/>)}
			</TooltipBadge>
		</div>
	)

}

const DisplayLocations = ({item, displayLocation}) => {

	if (!Array.isArray(item.locations) || item.locations.length === 0 || !item.locations_names) return null;

	let locationTooltipArray = []

	item.locations_names.slice(1).forEach((location) => {
		locationTooltipArray.push({
			name: location
		})
	})

	return <>
		<span className={"me-1 display-locations d-flex align-items-center"}>
			<CiLocationOn className="me-1" size={28}/> <span onClick={() => displayLocation({
			name: item.locations_names[0],
			lng: item.locations[0].lon,
			lat: item.locations[0].lng
		})}>{item.locations_names[0]}</span>
			<TooltipBadge length={locationTooltipArray.length}/>
		</span>
	</>;
};

const ItemFooter = ({item, setIndexUiState}) => {

	const {setTag, displayLocation, setCategory} = useItem(false, setIndexUiState)

	return (
		<div className={'item-footer d-flex flex-wrap'}>
			<DisplayCategories categories={item.categories} setCategory={setCategory}/>
			<DisplaySalary item={item}/>
			<DisplayTags tags={item.tags} setTag={setTag}/>
			<DisplayLocations item={item} displayLocation={displayLocation}/>
		</div>
	);

};

export default ItemFooter;
