import React from "react";
import visa from '../../../../assets/images/payments/visa.png'
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import usePopup from "../../../../Hooks/ReduxHooks/usePopup";
import CloseOutside from "../../../Parts/CloseOutside";
import stripeConfig from "../../../../Config/stripeConfig";
import CloseIcon from "../../../Parts/CloseIcon";
import useApi from "../../../../Hooks/useApi";
import usePayment from "../../../../Hooks/ReduxHooks/usePayment";
import {Rings} from "react-loader-spinner";

const EditCard = ({subscriptionData}) => {

	const Content = () => {

		const {closePopup} = usePopup();

		const {handleFetchSubscriptionData, handlePostBillingPayment, loadings} = usePayment();

		const stripe = useStripe();

		const elements = useElements();

		const handleSubmit = async (e) => {
			e.preventDefault();

			if(!stripe || !elements){
				return;
			}

			const cardElement = elements.getElement(CardElement);

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
			});

			if(error){
				return
			}
			await handlePostBillingPayment({paymentMethodId: paymentMethod.id, subscriptionId: subscriptionData.id})
			closePopup();
			handleFetchSubscriptionData()
		}

		return(
			<CloseOutside handleClose={closePopup}>
				<div className={'bg-white rounded-4 pt-4 pb-4 ps-4 pe-4 mt-4 position-relative'}>
					<CloseIcon handleClose={closePopup} additionalStyles={'mt-3 me-3'} />
					<form onSubmit={handleSubmit} className={'mt-3'}>
						<h3 className={'font-xxxl mb-4 w-75'}>
							Update your card details
						</h3>
						<div className={'mb-3'}>
							<CardElement className={'edit-card-element'} />
						</div>
						<button type={'submit'} className={'btn btn-primary'}>
							{loadings.postBillingPayment ? <Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/> : "Update"}
						</button>
					</form>
				</div>
			</CloseOutside>
		)
	}

	const {stripePromise, options} = stripeConfig(subscriptionData);

	return(
		<Elements stripe={stripePromise} options={options}>
			<Content clientSecret={options.clientSecret} />
		</Elements>
	)
}

const StripeCompleted = ({subscriptionData}) => {

	const {openPopup} = usePopup();

	const card = subscriptionData.payment_method.card;

	return (
		<div className={'d-flex flex-xl-row flex-column'}>
				<img width={200} src={visa} />
				<div className={'ms-3 d-flex flex-column justify-content-center'}>
					<p className={'text-uppercase text-gray mb-0 font-sm'}>Podstawowa</p>
					<p className={'text-primary mb-0 font-weight-lg font-xl'}>Visa **** {card.last4}</p>
					<p className={'text-gray mb-0 font-sm'}>Data ważności: {card.exp_month >= 10 ? '' : 0}{card.exp_month}/{card.exp_year.toString().slice(2)}</p>
					<div className={'mt-3'}>
						<button className={'border-0 bg-transparent text-primary p-0 me-2'} onClick={() => openPopup(EditCard, {subscriptionData})}>
							Edit
						</button>
					</div>
				</div>
		</div>
	)
}

export default StripeCompleted;