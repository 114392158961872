import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {ClearFilters, CustomRefinementList, FilterEarnings, FiltersLanguage, FiltersPremium} from './FiltersParts';
import {useInstantSearch,} from 'react-instantsearch';
import {Spinner} from "react-bootstrap";

const FiltersDropdown = ({setVisible, children}) => {

    const {t} = useTranslation();

    const {indexUiState, setIndexUiState, status} = useInstantSearch();

    const [filters, setFilters] = useState({
        refinementList: {},
        toggle: {},
        configure: {
            filters: '',
        }
    });

    useEffect(() => {

        setFilters(prev => ({
            ...prev,
            refinementList: {
                working_mode: indexUiState.refinementList?.working_mode || [],
                working_time: indexUiState.refinementList?.working_time || [],
                contract: indexUiState.refinementList?.contract || [],
                "languages.language": "refinementList" in indexUiState && "languages.language" in indexUiState.refinementList ? indexUiState.refinementList["languages.language"] : [],
            },
            toggle: {
                isPremium: indexUiState.toggle?.isPremium || false,
            },
            configure: {
                filters: indexUiState.configure?.filters || '',
            }
        }))

    }, [indexUiState]);

    /**
     *
     * pass local state to InstantSearch
     *
     */
    const handleSubmit = () => {

        setIndexUiState((prev) => {
            return {
                ...prev,
                refinementList: {
                    ...prev.refinementList,
                    ...filters.refinementList
                },
                toggle: {
                    ...prev.toggle,
                    ...filters.toggle,
                },
                configure: {
                    ...prev.configure,
                    filters: filters.configure.filters,
                }
            };
        });

        setVisible(false);

    }

    const handleReset = () => {

        setFilters({
            refinementList: {},
            toggle: {},
            configure: {}
        });
       setVisible(false);

    }

    return (
        <div className={'app-filters'}>
            {children}
            <FiltersPremium setFilters={setFilters} filters={filters}/>
            <div className="d-flex flex-column flex-md-row">
                <div className={'column'}>
                    <CustomRefinementList attribute="working_mode" title={t('browse.filters.working_mode.title')} setFilters={setFilters} filters={filters}/>
                    <CustomRefinementList attribute="working_time" title={t('browse.filters.working_time.title')} setFilters={setFilters} filters={filters}/>
                </div>
                <div className={'column'}>
                    <CustomRefinementList attribute="contract" setFilters={setFilters} filters={filters} title={t('browse.filters.contract.title')}/>
                </div>
                <div className={'column'}>
                    <FiltersLanguage setFilters={setFilters} filters={filters} title={t('browse.filters.languages.title')}/>
                </div>
                <div className={'column'}>
                    <FilterEarnings setFilters={setFilters} filters={filters}/>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="filter-buttons">
                    <button onClick={handleSubmit} type="button" className="pt-2 pb-2 w-100 btn btn-primary mt-2 text-uppercase">
                        {status !== 'idle' ? <Spinner size={'sm'}/> : <>{t('parts.header.searchOptions.restartFilters')}</>}
                    </button>
                    <ClearFilters handleReset={handleReset}/>
                </div>
            </div>
        </div>
    );
};

export default FiltersDropdown;