import thumbnailWhiteIcon from '../../../assets/svg/premium/thumbnail-white.svg';
import thumbnailIcon from '../../../assets/svg/premium/thumbnail.svg';
import {useState} from "react";
import {useNavigate} from "react-router";
import useUser from "../../../Hooks/ReduxHooks/useUser";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";
import Auth from "../../Auth/Auth";
import i18n from "i18next";
import premiumTranslations from "../Translations/en/premiumTranslations.json";
import {useTranslation} from "react-i18next";

const PremiumPayment = () => {

	i18n.addResources('en', 'premium', premiumTranslations.premiumPayment);

	const { t } = useTranslation('premium');

	const [isChecked, setIsChecked] = useState(false)

	const navigate = useNavigate();

	const {user} = useUser();

	const {openPopup} = usePopup();

	const changePayment = (e) => {
		setIsChecked(e.target.checked)
	}

	const [isHoverActive, setIsHoveActive] = useState(false);

	const paymentNavigate = () => {
		!user ? openPopup(Auth, {type: 'login'}) : navigate('/account/billing')
	}

	return(
		<div className="premium-payment pt-3 pb-3 ps-4 pe-4 rounded-5 h-100 d-flex flex-column justify-content-between">
			<div className="d-flex align-items-center justify-content-between">
				<p className={`text-uppercase mb-0 font-xl ${!isChecked ? 'font-weight-xl text-primary' : ''}`}>{t('monthly')}</p>
				<label className="toggle d-flex justify-content-between align-items-center cursor-pointer">
					<input className="toggle-checkbox" type="checkbox" onChange={changePayment} />
					<div className="toggle-switch"></div>
				</label>
                <p className={`text-uppercase mb-0 font-xl ${isChecked ? 'font-weight-xl text-primary' : ''}`}>{t('annually')}</p>
			</div>
            <div className="d-flex flex-column align-items-center mt-3 mb-3">
                <div>
                    <p className="font-xxl text-primary font-weight-lg mb-0">{t('premiumTitle')}</p>
                    <p className="m-0 text-primary font-weight-md price-wrapper">
						{!isChecked ? '$9.99' : '$1.99'}
                        <span className="ms-2">/{t('perMonth')}</span>
                    </p>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center">
                <button onClick={paymentNavigate} onMouseEnter={() => setIsHoveActive(true)} onMouseLeave={() => setIsHoveActive(false)} className="font-xxl text-uppercase btn btn-primary w-100 pt-3 pb-3 d-flex align-items-center justify-content-center">
                    <img src={!isHoverActive ? thumbnailWhiteIcon : thumbnailIcon} alt="Icon" className="me-2" />
					{t('getPremium')}
                </button>
				{/*<Link to="" className="font-family-open-sans text-uppercase verify-link font-md text-center mt-2 text-decoration-underline">*/}
				{/*	Or verify only your account*/}
				{/*</Link>*/}
            </div>
		</div>
	)
}

export default PremiumPayment;