import Item from '../Browse/Item/Item';
import {RelatedProducts, useInstantSearch} from 'react-instantsearch';
import React from 'react';
import './Assets/related.scss';
import {useNavigate} from "react-router";
import itemLink from "../../utils/itemLink";
import useUser from "../../Hooks/ReduxHooks/useUser";

export const RelatedItems = ({id}) => {

	const {isMobile} = useUser();

	const SimilarItem = ({item}) => {

		const navigate = useNavigate();

		const {setIndexUiState} = useInstantSearch();

		return (
			<>
				<Item item={item} handleClick={() => navigate(`${itemLink(false, item.uuid, item.name)}`)} setIndexUiState={setIndexUiState}/>
			</>
		)
	}

	return (
		<div>
			<RelatedProducts
				objectIDs={[`user-${id}`]}
				itemComponent={SimilarItem}
				headerComponent={() => <h2 className={'mt-5'}>Looking similar</h2>}
				limit={isMobile ? 4 : 8}
				emptyComponent={() => <p>No recommendations.</p>}
			/>
		</div>
	)

}