import useMessage from "../../../../Hooks/ReduxHooks/useMessage";
import {ChevronLeft} from "react-feather";
import MoreOptions from "../../../Parts/Misc/MoreOptions";
import React from "react";
import SkeletonTopPanel from "../../Skeleton/SkeletonTopPanel";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import placeholderImage from "../../../../assets/images/placeholder.jpg";
import {useNavigate} from "react-router";
import itemLink from "../../../../utils/itemLink";

const UserLogoContent = ({url}) => {

	const handleError = (e) => {
		e.target.src = placeholderImage;
	}

	return(
		<div className="avatar rounded-5">
			{url ? <img src={url} alt="Placeholder Image" onError={handleError} /> : <img src={placeholderImage} alt="Placeholder Image" />}
		</div>
	)
}

const TopPanel = () => {

	const {conversationData, handleSetChatID, loadings, chatID} = useMessage()

	const {isMobile} = useUser();

	const navigate = useNavigate();

	const profileLink = (receiverId, receiverName) => {
		if(receiverId === 'JOBOT2a1-f8ae-401c-9c7c-d8ec17488229'){
			return
		}
		navigate(itemLink(false, receiverId, receiverName))
	}

	return(
		<div className="p-3 top-panel border-bottom d-flex justify-content-between align-items-center w-100">
			{loadings.conversationData ?
				<SkeletonTopPanel />
				:
				<div className="d-flex align-items-center">
					{isMobile && <ChevronLeft onClick={() => handleSetChatID(null)} className="me-2 text-primary cursor-pointer" />}
					<div className={`d-flex align-items-center user`}>
						<UserLogoContent url={conversationData.receiverAvatar} />
						<div className="d-flex flex-column ms-2">
							<button onClick={() => profileLink(conversationData.receiverId, conversationData.receiverName)} className={`text-dark bg-transparent border-0 p-0 font-weight-md ${conversationData.unreadCount > 0 ? 'unread' : ''}`}>
								{conversationData.receiverName !== null ? conversationData.receiverName : 'No name'}
							</button>
						</div>
					</div>
				</div>
			}
			{conversationData.receiverId !== 'JOBOT2a1-f8ae-401c-9c7c-d8ec17488229' && <MoreOptions item={chatID} options={['ArchiveConversation']} />}
		</div>
	)
}

export default TopPanel;