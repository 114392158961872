import './Assets/auth.scss';
import './Assets/auth-media-query.css';
import { useEffect, useState } from "react";
import CloseIcon from "../Parts/CloseIcon";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as LogoMobile } from '../../assets/svg/logo-mobile.svg';
import Login from "./Login/Login";
import Register from "./Register/Register";
import CloseOutside from "../Parts/CloseOutside";
import Verify from "./Verify/Verify";
import Forgot from "./Forgot/Forgot";
import ForgotVerify from "./ForgotVerify/ForgotVerify";
import Reset from "./Reset/Reset";
import useUser from "../../Hooks/ReduxHooks/useUser";
import useQueryString from "../../Hooks/useQueryString";
import usePopup from "../../Hooks/ReduxHooks/usePopup";
import authImage1 from '../../assets/images/auth/1.webp';
import authImage2 from '../../assets/images/auth/2.webp';
import authImage3 from '../../assets/images/auth/3.webp';
import authImage4 from '../../assets/images/auth/4.webp';
import authImage5 from '../../assets/images/auth/5.webp';
import authImage6 from '../../assets/images/auth/6.webp';
import authImage7 from '../../assets/images/auth/7.webp';
import authImage8 from '../../assets/images/auth/8.webp';
import authImage9 from '../../assets/images/auth/9.webp';
import authImage10 from '../../assets/images/auth/10.webp';

const Auth = ({ type }) => {
	const [authType, setAuthType] = useState(type || 'login');
	const { removeQuery } = useQueryString();
	const { isMobile } = useUser();
	const { closePopup } = usePopup();

	useEffect(() => {
		const email = localStorage.getItem('email');
		const forgotEmail = localStorage.getItem('forgot-email');
		const forgotVerifyCode = localStorage.getItem('forgot-verify-code');

		if (email) {
			setAuthType('verify');
		} else if (forgotEmail) {
			setAuthType(forgotVerifyCode ? 'reset' : 'forgot-verify');
		}
	}, []);

	const images = [authImage1, authImage2, authImage3, authImage4, authImage5, authImage6, authImage7, authImage8, authImage9, authImage10];
	const getRandomImage = () => images[Math.floor(Math.random() * images.length)];

	const authConfiguration = {
		login: <Login setAuthType={setAuthType} />,
		register: <Register setAuthType={setAuthType} />,
		verify: <Verify setAuthType={setAuthType} />,
		forgot: <Forgot setAuthType={setAuthType} />,
		'forgot-verify': <ForgotVerify setAuthType={setAuthType} />,
		reset: <Reset setAuthType={setAuthType} />,
	};

	const backgroundImage = getRandomImage();

	const handleClose = () => {
		closePopup();
		removeQuery('auth-popup');
	};

	return (
		<div className="auth d-flex bg-white position-relative overflow-y-scroll">
			<CloseOutside handleClose={handleClose}>
				<CloseIcon handleClose={handleClose} additionalStyles="mt-2 me-2" />
				<Row>
					{!isMobile && (
						<Col>
							<div
								style={{ backgroundImage: `url("${backgroundImage}")` }}
								className="auth-background-image h-100 position-relative"
							>
								<div className="bg-primary h-100 auth-background-color"></div>
								<div className="auth-logo-wrapper">
									<LogoMobile />
								</div>
								<div className="auth-text-wrapper position-absolute bottom-0 start-0 ms-5 mb-5 text-white font-weight-md text-uppercase">
									Offer yours <br />
									<strong>or search</strong> <br />
									for services
								</div>
							</div>
						</Col>
					)}
					<Col className="mt-5 mb-5 ps-5 pe-5">
						{authConfiguration[authType]}
					</Col>
				</Row>
			</CloseOutside>
		</div>
	);
};

export default Auth;
