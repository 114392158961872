import {Col, Container, Row} from "react-bootstrap";
import SingleSkeleton from "./SingleSkeleton";
import ProfileStatus from "../Profile/Common/ProfileStatus";
import SingleHeader from "./Header/SingleHeader";
import PremiumBadge from "../Premium/Parts/PremiumBadge";
import Thumbnail from "../Browse/Thumbnail/Thumbnail";
import Contact from "./Contact";
import Gallery from "./Type/Parts/Gallery";
import Freelance from "./Type/Freelance/Freelance";
import FloatingButtons from "../Parts/FloatingButtons";
import React, {useRef} from "react";
import useSingle from "./useSingle";
import {RelatedItems} from "./Related";
import SingleNotFound from "./SingleNotFound";

const Content = ({id, containerRef, loading, thumbnail, gallery, item}) => {

	if(item === 404) {

		return (
			<Container>
				<SingleNotFound/>
			</Container>
		);

	}

	if(loading) {

		return (
			<Container>
				<SingleSkeleton/>
			</Container>
		);

	}

	return (
		<Container>
			<div className={`page-single type-${item && item.type}`}>
				<ProfileStatus />
				{item &&
					<>
						<SingleHeader loading={loading} item={item}/>
						<Container>
							<Row>
								<Col md={12} lg={4}>
									<div className={`thumbnail-preview position-relative ${item.plan_id === 'premium' ? 'is_premium' : ''}`}>
										<PremiumBadge planID={item.plan_id} marginRight={2} />
										<Thumbnail item={thumbnail} autoplay={false}/>
									</div>
									<Contact item={item} />
									<div className={`single-gallery`}>
										<Gallery gallery={gallery}/>
									</div>
								</Col>
								<Col md={12} lg={8} className="mt-lg-0 mt-3 position-relative">
									<Freelance item={item} loading={loading} gallery={gallery} />
								</Col>
							</Row>
							{/*TODO*/}
							{id && <RelatedItems id={id}/>}
						</Container>
					</>
				}
				<FloatingButtons data={item} containerRef={containerRef} />
			</div>
		</Container>
	);
}

export default Content;