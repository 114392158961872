import logo from '../../../assets/logo.svg';
import './Header.scss';
import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import useUser from "../../../Hooks/ReduxHooks/useUser";
import Notifications from './Notifications';
import Search from './Search/Search';
import Navigation from './Navigation';
import {useInstantSearch} from 'react-instantsearch';

const Logo = () => {

	const {setIndexUiState} = useInstantSearch();

	const {handleSetGeo} = useUser();

	const handleClick = (e) => {

		// reset index ui state
		setIndexUiState({
			configure: {
				filters: ''
			}
		});

		handleSetGeo({
			selected: false,
			radius: 50,
			lat: false,
			lng: false,
			name: '',
		});

	};

	return (
		<Link to="/" onClick={handleClick} className={'top-logo'}>
			<img src={logo} className={'logo'} alt={'logo'}/>
		</Link>
	);

};

const Header = () => {

	const appTopBarRef = useRef();

	const {isMobile} = useUser();

	const location = useLocation();

	const [scroll, setScroll] = useState(0);

	const [appTopBarHeight, setAppTopBarHeight] = useState(null);

	const [hidden, setHidden] = useState(false);

	const [lastScrollTop, setLastScrollTop] = useState(0);

	const handleScroll = () => {
		const currentScrollTop = window.scrollY;
		const scrollDifference = currentScrollTop - lastScrollTop;

		if (currentScrollTop > 100 && scrollDifference > 50) {
			setHidden(true);
		}

		else if (scrollDifference < -50) {
			setHidden(false);
		}

		setLastScrollTop(currentScrollTop);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [lastScrollTop]);

	useEffect(() => {
		setAppTopBarHeight(appTopBarRef.current?.clientHeight);
	}, [appTopBarRef.current?.clientHeight]);

	if (location.pathname !== '/' && isMobile) {
		return null;
	}

	return (
		<div style={{ paddingTop: `${appTopBarHeight ? appTopBarHeight : 0}px` }} className={'padding-wrapper'}>
			<div className={`app-top-bar ${hidden ? 'hidden' : 'show'}`} ref={appTopBarRef}>
				<Notifications/>
				<Container>
					<div className={`app-top-bar-wrapper`}>
						<Logo/>
						<Search/>
						<Navigation/>
					</div>
				</Container>
			</div>
		</div>
	);
};

export default Header;
