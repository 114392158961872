import React, {useState} from "react";
import {Link} from "react-router-dom";
import useUser from "../../Hooks/ReduxHooks/useUser";
import usePopup from "../../Hooks/ReduxHooks/usePopup";
import Auth from "../Auth/Auth";

const Contact = ({item}) => {

	const [contactVisible, setContactVisible] = useState(false)

	const {user} = useUser();

	const {openPopup} = usePopup();

	const itemsConfig = [
		{
			key: 'direct',
			visible: item.contact_methods.direct,
			link: `/messages?uid=${item.userId}`,
			text: 'Direct message'
		},
		{
			key: 'phone',
			visible: item.contact_methods.phone,
			link: `tel:${item.contact_methods.phone}`,
			text: `Phone: ${item.contact_methods.phone}`
		},
		{
			key: 'email',
			visible: item.contact_methods.email,
			link: `mailto:${item.contact_methods.email}`,
			text: `Email: ${item.contact_methods.email}`
		}
	];

	const changeVisible = () => {
		if(user === null){
			return openPopup(Auth, {type: 'login'})
		}
		setContactVisible(prevState => !prevState)
	}

	if(!item.contact_methods.phone && !item.contact_methods.email && !item.contact_methods.direct){
		return
	}

	return(
		<div className={'mt-2'}>
			<button className={'btn btn-primary w-100'} onClick={changeVisible}>
				Contact
			</button>
			{contactVisible &&
				<div>
					{itemsConfig.map((item, index) => (
						<React.Fragment key={index}>
							{item.visible &&
								<>
									{item.key === 'direct' &&
										<Link to={item.link} className={'btn btn-outline-primary w-100 mt-2'}>
											{item.text}
										</Link>
									}
									{(item.key === 'phone' || item.key === 'email') &&
										<a href={item.link} className={'btn btn-outline-primary w-100 mt-2'}>
											{item.text}
										</a>
									}
								</>
							}
						</React.Fragment>
					))}
				</div>
			}
		</div>
	)
}

export default Contact;