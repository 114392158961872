import {useEffect, useState} from "react";
import './Assets/single.scss';
import './Assets/single-media-query.scss';
import useApi from "../../Hooks/useApi";
const useSingle = (id) => {

	const {request} = useApi();

	const [loading, setLoading] = useState();

	const [item, setItem] = useState(false);

	const [gallery, setGallery] = useState();

	const [thumbnail, setThumbnail] = useState()

	/**
	 *
	 * Get job/profile contents
	 *
	 */

	useEffect(() => {

		setLoading(true);

		const controller = new AbortController();

		request({
			method: "get",
			url: id ? `/user/${id}` : "/profile/preview",
			onSuccess: (data) => {
				setItem(data.details);
				setGallery(data.gallery);
				setThumbnail(data);
			},
			onError: () => {
				setItem(404);
			}
		}).finally(() => {

			setLoading(false);

		})

		return () => { controller.abort(); };

	}, [id]);

	/**
	 *
	 * Return single
	 *
	 */

	return {loading, item, gallery, thumbnail};

};

export default useSingle;
