import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import callToAPI from "../../api";

export const fetchSubscriptionData = createAsyncThunk(
	'payment/fetchSubscriptionData',
	async (_, {getState}) => {
		try{
			return await callToAPI({
				url: '/billing/subscription/',
				method: 'get',
			})
		}
		catch(err){
			console.log(err);
		}
	}
)

export const fetchBillingSubscriptionPlanID = createAsyncThunk(
	'payment/fetchBillingSubscriptionPlanID',
	async(planID) => {
		try{
			return await callToAPI({
				url: `/billing/subscription/${planID}`,
				method: 'get'
			})
		}
		catch(err){
			console.log(err)
		}
	}
)

export const deleteBillingSubscriptionPlanID = createAsyncThunk(
	'payment/deleteBillingSubscriptionPlanID',
	async (planID) => {
		try{
			return await callToAPI({
				url: `/billing/subscription/${planID}`,
				method: 'delete'
			})
		}
		catch(err){
			console.log(err)
		}
	}
)

export const postBillingPayment = createAsyncThunk(
	'payment/postBillingPayment',
	async ({paymentMethodId, subscriptionId}) =>{
		try{
			return await callToAPI({
				url: '/billing/payment',
				method: 'post',
				body: {
					paymentMethodId: paymentMethodId,
					subscriptionId: subscriptionId
				},
			})
		}
		catch(err){
			console.log(err)
		}
	}
)

export const fetchBillingAddress = createAsyncThunk(
	'payment/fetchBillingAddress',
	async () => {
		try{
			return await callToAPI({
				method: 'get',
				url: '/billing/address',
			})
		}
		catch(err){
			console.log(err)
		}
	}
)

export const postBillingAddress = createAsyncThunk(
	'payment/postBillingAddress',
	async (data) => {
		try{
			return await callToAPI({
				url: `/billing/address`,
				method: 'post',
				body: data,
			})
		}
		catch(err){
			console.log(err)
		}
	}
)

export const fetchBillingInvoices = createAsyncThunk(
	'payment/fetchBillingInvoices',
	async () => {
		try{
			return await callToAPI({
				url: '/billing/invoices',
				method: 'get',
			})
		}
		catch(err){
			console.log(err)
		}
	}
)

const initialState = {
	subscriptionData: null,
	addressData: null,
	invoicesData: null,
	loadings: {
		subscriptionData: true,
		postBillingPayment: false,
		fetchBillingAddress: false,
		postBillingAddress: false,
		fetchBillingInvoices: false,
		fetchBillingSubscriptionPlanID: false,
		deleteBillingSubscriptionPlanID: false
	}
};

const paymentSlice = createSlice({
	name: 'payment',
	initialState: initialState,
	reducers: {
		setSubscriptionData: (state, action) => {
			state.subscriptionData = action.payload;
		},
		setLoadings(state, action) {
			const { field, value } = action.payload;
			state.loadings[field] = value;
		},
	},
	extraReducers: (builder) => {

		builder.addCase(fetchSubscriptionData.fulfilled, (state, action) => {
			state.subscriptionData = action.payload;
			state.loadings.subscriptionData = false;
		})
		builder.addCase(fetchSubscriptionData.pending, (state, action) => {
			state.loadings.subscriptionData = true;
		})

		builder.addCase(postBillingPayment.fulfilled, (state, action) => {
			state.loadings.postBillingPayment = false
		})
		builder.addCase(postBillingPayment.pending, (state, action) => {
			state.loadings.postBillingPayment = true
		})

		builder.addCase(fetchBillingAddress.fulfilled, (state, action) => {
			state.addressData = action.payload;
			state.loadings.fetchBillingAddress = false
		})
		builder.addCase(fetchBillingAddress.pending, (state, action) => {
			state.loadings.fetchBillingAddress = true
		})

		builder.addCase(postBillingAddress.fulfilled, (state, action) => {
			state.addressData = action.payload;
			state.loadings.postBillingAddress = false
		})
		builder.addCase(postBillingAddress.pending, (state, action) => {
			state.loadings.postBillingAddress = true
		})

		builder.addCase(fetchBillingInvoices.fulfilled, (state, action) => {
			state.invoicesData = action.payload;
			state.fetchBillingInvoices = false;
		})
		builder.addCase(fetchBillingInvoices.pending, (state, action) => {
			state.fetchBillingInvoices = true;
		})

		builder.addCase(fetchBillingSubscriptionPlanID.fulfilled, (state, action) => {
			state.subscriptionData = action.payload;
			state.loadings.subscriptionData = false;
			state.loadings.fetchBillingSubscriptionPlanID = false;
		})
		builder.addCase(fetchBillingSubscriptionPlanID.pending, (state, action) => {
			state.loadings.subscriptionData = true;
			state.loadings.fetchBillingSubscriptionPlanID = true;
		})

		builder.addCase(deleteBillingSubscriptionPlanID.fulfilled, (state, action) => {
			state.loadings.deleteBillingSubscriptionPlanID = false;
		})
		builder.addCase(deleteBillingSubscriptionPlanID.pending, (state, action) => {
			state.loadings.deleteBillingSubscriptionPlanID = true;
		})

	}
})

export const {setSubscriptionData, setLoadings} = paymentSlice.actions;
export default paymentSlice.reducer;
