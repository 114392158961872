import {Button, Form} from "react-bootstrap";
import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Notification from '../../../utils/NotificationUtils';
import CloseOutside from '../CloseOutside';
import CloseIcon from '../CloseIcon';
import {BsFacebook, BsLinkedin, BsTwitterX} from "react-icons/bs";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";
import itemLink from "../../../utils/itemLink";
import {FiLink} from "react-icons/fi";

const SocialMediaLink = ({item, url}) => {

	return(
		<Link to={`${item.link}${url}`} target="_blank">
			{item.icon}
		</Link>
	)

}

const ShareWindowLinks = ({url, config}) => {

	return config.map((item, index) => <SocialMediaLink key={index} item={item} url={url}/>)

}

const ShareWindow = ({item}) => {

	const {closePopup} = usePopup();

	const handleClose = () => {
		closePopup();
	}

	const { t } = useTranslation();

	const linkRef = useRef(null);

	const config = [
		{
			icon: <BsFacebook className="social-icon" />,
			link: `https://www.facebook.com/sharer/sharer.php?u=${itemLink(true, item.uuid, item.name)}`
		},
		{
			icon: <BsTwitterX className="social-icon" />,
			link: `https://twitter.com/intent/tweet?url=${itemLink(true, item.uuid, item.name)}`,
		},
		{
			icon: <BsLinkedin className="social-icon" />,
			link: `https://www.linkedin.com/shareArticle?mini=true&url=${itemLink(true, item.uuid, item.name)}`
		}
	]

	const handleCopyUrl = () => {
		if (linkRef.current) {
			linkRef.current.select();
			document.execCommand('copy');
			Notification.success(t('parts.misc.shareWindow.notification'));
		}
	};

	return(
		<>
			<CloseOutside handleClose={handleClose}>
				<div className="share-window">
					<CloseIcon handleClose={handleClose} additionalStyles="mt-3 me-3" />
					<div className="d-flex justify-content-between mb-2">
						<h3>{t('parts.misc.shareWindow.title')}</h3>
					</div>
					<div className="d-flex mb-3">
						<div className="position-relative copy-link-wrapper">
							<label>{t('parts.misc.shareWindow.label')}</label>
							<Form.Control ref={linkRef} type="text" defaultValue={itemLink(true, item.uuid, item.name)} readOnly/>
						</div>
						<Button className="btn btn-primary sm btn-circle ms-3" onClick={handleCopyUrl}><FiLink /></Button>
					</div>
					<p className={'m-0 text-center font-md'}>
						or share directly on:
					</p>
					<div className="d-flex justify-content-center gap-2 mt-2">
						<ShareWindowLinks link={itemLink(true, item.uuid, item.name)} config={config}/>
					</div>
				</div>
			</CloseOutside>
		</>
	)
}

export default ShareWindow;
