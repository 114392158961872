import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import configSlice from "./slices/configSlice";
import webSocketSlice from "./slices/webSocketSlice";
import popupSlice from "./slices/popupSlice";
import errorSlice from "./slices/errorSlice";
import backgroundSlice from "./slices/backgroundSlice";
import messageSlice from "./slices/messageSlice";
import watchlistSlice from "./slices/watchlistSlice";
import paymentSlice from "./slices/paymentSlice";

const store = configureStore({
	reducer: {
		user: userSlice,
		config: configSlice,
		webSocket: webSocketSlice,
		popup: popupSlice,
		error: errorSlice,
		background: backgroundSlice,
		message: messageSlice,
		watchlist: watchlistSlice,
		payment: paymentSlice
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export default store;
