export default {
	Auth: {
		Cognito: {
			//  Amazon Cognito User Pool ID
			userPoolId: process.env.REACT_APP_USER_POOL_ID_COGNITO,
			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID_COGNITO,
			// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
			identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID_COGNITO,
			// OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
			allowGuestAccess: true,
			// OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
			// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
			signUpVerificationMethod: 'code', // 'code' | 'link'
			loginWith: {
				oauth: {
					domain: process.env.REACT_APP_COGNITO_DOMAIN,
					scopes: [
						"phone",
						"email",
						"openid",
						"profile",
						"aws.cognito.signin.user.admin"
					],
					redirectSignIn: [
						process.env.REACT_APP_URL,
						`https://${process.env.REACT_APP_COGNITO_DOMAIN}`
					],
					redirectSignOut: [
						process.env.REACT_APP_URL
					],
					responseType: "code"
				},
				federationTarget: "COGNITO_USER_POOLS",
				aws_cognito_username_attributes: [
					"EMAIL"
				],
				aws_cognito_social_providers: [
					"GOOGLE",
					"APPLE"
				],
				aws_cognito_signup_attributes: [
					"EMAIL"
				],
			}
		}
	}
};
// export default {
// 	Auth: {
// 		Cognito: {
// 			//  Amazon Cognito User Pool ID
// 			userPoolId: process.env.REACT_APP_USER_POOL_ID_COGNITO,
// 			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
// 			userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID_COGNITO,
// 			// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
// 			identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID_COGNITO,
// 			// OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
// 			allowGuestAccess: true,
// 			// OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
// 			// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
// 			signUpVerificationMethod: 'code', // 'code' | 'link'
// 			loginWith: {
// 				oauth: {
// 					domain: "krzychu.jobmultiverse.pl",
// 					// domain: "jobmultiverse-dev.auth.eu-north-1.amazoncognito.com",
// 					scopes: [
// 						"phone",
// 						"email",
// 						"openid",
// 						"profile",
// 						"aws.cognito.signin.user.admin"
// 					],
// 					redirectSignIn: [
// 						process.env.REACT_APP_URL,
// 						process.env.REACT_APP_REDIRECT_SIGN_IN_URL_COGNITO
// 					],
// 					redirectSignOut: [
// 						process.env.REACT_APP_URL
// 					],
// 					responseType: "code"
// 				},
// 				federationTarget: "COGNITO_USER_POOLS",
// 				aws_cognito_username_attributes: [
// 					"EMAIL"
// 				],
// 				aws_cognito_social_providers: [
// 					"GOOGLE",
// 					"APPLE"
// 				],
// 				aws_cognito_signup_attributes: [
// 					"EMAIL"
// 				],
// 			}
// 		}
// 	}
// };