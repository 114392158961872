import {Form} from "react-bootstrap";
import {ArrowRight, Smile} from "react-feather";
import EmojiPicker from "emoji-picker-react";
import {Rings} from "react-loader-spinner";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useMessage from "../../../../Hooks/ReduxHooks/useMessage";
import ProfileError from "../../../Profile/Edit/Parts/ProfileError";

const BottomPanel = ({scrollToBottom}) => {

	const { t } = useTranslation();

	const {sendMessage, loadings} = useMessage();

	const [messageValue, setMessageValue] = useState('');

	const [emojiPickerActive, setEmojiPickerActive] = useState();

	const sendInputRef = useRef();

	const [error, setError] = useState(false);

	const handleSend = () => {
		if(messageValue.trim().length === 0){
			return setError('Message cannot be empty');
		}
		setError(false)
		sendMessage(messageValue)
		setMessageValue('')
	}

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			if (!e.shiftKey) {
				e.preventDefault();
				handleSend();
			}
		}
	};

	const toggleEmojiPicker = () => {
		setEmojiPickerActive(prevState => !prevState);
	};

	const handleEmojiClick = (emojiObject) => {
		setMessageValue(prev => prev + emojiObject.emoji);
		setEmojiPickerActive(false);
	};

	useEffect(() => {
		scrollToBottom();
		sendInputRef.current.focus();
	}, [loadings.sendMessage])

	const handleChange = (e) => {
		setMessageValue(e.target.value);
		setError(false)
	}

	return(
		<div className="bottom-panel">
			<div className="d-flex mb-3 ms-3 me-3 pt-3 pb-3 align-items-end">
				<div className="d-flex w-100 position-relative">
					<Form.Control
						as="textarea"
						ref={sendInputRef}
						className={`font-md textarea-auto-resize send-text-area ${error ? 'border border-danger' : ''}`}
						onKeyDown={handleKeyDown}
						placeholder={loadings.sendMessage ? t('messages.messagePopup.bottomPanel.loading') : t('messages.messagePopup.bottomPanel.sendMessage')}
						value={messageValue}
						onChange={handleChange}
						style={{ resize: 'none', overflow: 'hidden'}}
					/>
					{error ?
						<ProfileError profileError={error} additionalStyles={'position-absolute top-50 end-0 translate-middle-y me-2'} />
						:
						<button className="position-absolute top-50 translate-middle-y end-0 bg-transparent border-0 me-2 text-primary" onClick={toggleEmojiPicker}>
							<Smile />
						</button>
					}
					{emojiPickerActive &&
						<EmojiPicker size={55} className="position-absolute bottom-100 mb-1 end-0" onEmojiClick={(emojiObject) => handleEmojiClick(emojiObject)}/>
					}
				</div>
				<button className="btn btn-primary btn-circle flex-shrink-0 ms-3" onClick={handleSend}>
					{loadings.sendMessage ? <Rings /> : <ArrowRight size={20} />}
				</button>
			</div>
		</div>
	)
}

export default BottomPanel;