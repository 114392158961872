import { Menu } from 'react-feather';
import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import useUser from "../../Hooks/ReduxHooks/useUser";
import Contact from "../Single/Floating/Contact";
import Floating from "../Single/Floating/Floating";
import ProfileButtons from "../Profile/Edit/ProfileButtons";
const FloatingButtons = ({ data, containerRef }) => {
	const location = useLocation();
	const { user, isMobile } = useUser();
	const [visibleButtons, setVisibleButtons] = useState(false);

	const checkIsCurrentRoute = (routes) => {
		if (routes.includes(location.pathname)) {
			return true;
		}

		const pathNameArray = location.pathname.split('/');

		return routes.findIndex(route => {
			const routeArray = route.split('/');

			if (routeArray.length !== pathNameArray.length) {
				return false;
			}

			for (let i = 0; i < routeArray.length; i++) {
				if (routeArray[i] !== pathNameArray[i] && !routeArray[i].includes(':')) {
					return false;
				}
			}

			return true;
		}) > -1;
	}

	const buttons = [
		{
			validation: checkIsCurrentRoute(['/account']),
			component: () => <Floating item={data} />
		},
		{
			validation: checkIsCurrentRoute(['/account/edit']),
			component: () => <ProfileButtons />,
		},
		{
			validation: checkIsCurrentRoute(['/account', '/profile/:id', '/profile/:id/:slug']),
			component: () => <Contact item={data} />
		}
	];

	const handleMenuClick = () => {
		setVisibleButtons(prevState => !prevState);
	}

	if (!containerRef?.current) {
		return null;
	}

	return (
		<div className="position-fixed z-3 bottom-0" style={{ right: `${(document.body.offsetWidth - containerRef.current.offsetWidth) / 2}px` }}>
			<div className={`content-buttons ${checkIsCurrentRoute(['/profile/jobs/:id/edit', '/profile/jobs/add', '/profile/jobs/add/:id/preview']) || (isMobile && user) ? 'bottom-margin' : ''}`}>
				<div>
					{buttons.map((el, index) => {
						if (!el.validation) {
							return null;
						}

						return (
							<div className="d-flex flex-column" key={index}>
								{visibleButtons && <>{el.component()}</>}
							</div>
						)
					})}
				</div>
				<button id="floating-buttons" type="button" className={`btn btn-primary btn-circle`} onClick={handleMenuClick}>
					<Menu />
				</button>
			</div>
		</div>
	);
}

export default FloatingButtons;
