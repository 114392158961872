import React from 'react';
import {useTranslation} from 'react-i18next';
import {Briefcase, Clock, Coffee, DollarSign, FileText, Folder, Globe, MapPin} from 'react-feather';
import AppConfig from '../../../../Config/AppConfig';
import useItem from '../../../../Hooks/useItem';
import {firstLetterUpper} from "../../../../utils/AppUtils";
import {TooltipBadge} from '../../../Parts/Tooltips';
import {useInstantSearch} from "react-instantsearch";
import useConfig from "../../../../Hooks/ReduxHooks/useConfig";

const Categories = ({data, icon, item}) => {

	const {setIndexUiState} = useInstantSearch();

	const {setCategory} = useItem(item, setIndexUiState)

	return(
		<>
			<span className="me-1 d-flex justify-content-center">{icon}</span>
			{data.map((el, index) => <p key={index} onClick={() => setCategory(el.name.toLowerCase())} className="mb-0 me-1 cursor-pointer font-family-open-sans">{el.name}{data.length !== index + 1 && ','}</p>)}
		</>
	)
}

const LocationsTags = ({data, icon, item, name, numberOfItems}) => {

	const {setIndexUiState} = useInstantSearch();

	const {displayLocation, setTag} = useItem(item, setIndexUiState)

	return(
		<>
			<span className="me-1 d-flex justify-content-center">{icon}</span>
			{Array.isArray(data) && data.slice(0, numberOfItems).map((item, index) => (
				<p onClick={() => name === 'location' ? displayLocation(item) : setTag(item.name)} className="mb-0 me-1 cursor-pointer font-family-open-sans" key={index}>
					{item.name}{data.length !== index + 1 && ','}
				</p>
			))}
			<p className="mb-0">
				{data.length > numberOfItems &&
					<TooltipBadge length={data.slice(numberOfItems).length}>
						<div className={'d-flex flex-column'}>
							{data.map((tag, index) => <div className={'cursor-pointer'} onClick={() => setTag(tag.name)} key={index}>{tag.name}</div>)}
						</div>
					</TooltipBadge>
				}
			</p>
		</>
	)
}

const Languages = ({data, icon, numberOfItems}) => {

	return(
		<>
			<span className="me-1 d-flex justify-content-center">{icon}</span>
			{Array.isArray(data) && data.slice(0, numberOfItems).map((item, index) => (
				<p className="mb-0 me-1 cursor-pointer font-family-open-sans" key={index}>
					{firstLetterUpper(item.language)} {firstLetterUpper(item.level)}{data.length !== index + 1 && ','}
				</p>
			))}
			<p className="mb-0">
				{data.length > numberOfItems &&
					<TooltipBadge length={data.slice(numberOfItems).length}>
						{data.map((language, index) =>
							<div key={index}>
								{firstLetterUpper(language.language)} {firstLetterUpper(language.level)}{data.length !==index + 1 && ','}
							</div>
						)}
					</TooltipBadge>
				}
			</p>
		</>
	)
}

const Other = ({data, icon, numberOfItems}) => {

	return(
		<>
			<span className="me-1 d-flex justify-content-center">{icon}</span>
			{Array.isArray(data) && data.slice(0, numberOfItems).map((item, index) => (
				<p className="mb-0 me-1 cursor-pointer font-family-open-sans" key={index}>
					{item}{data.length !== index + 1 && ','}
				</p>
			))}
			<p className="mb-0">
				{data.length > numberOfItems &&
					<TooltipBadge length={data.slice(numberOfItems).length}>
						{data.map((item, index) => (
							<div key={index}>
								{item}
							</div>
						))}
					</TooltipBadge>
				}
			</p>
		</>
	)
}

const InformationItem = ({name, icon, data, numberOfItems, item}) => {
	if (data === null || data.length === 0) {
		return <></>
	}

	return (
		<div className="d-flex align-items-center me-4 mt-2 text-nowrap flex-wrap">
			{name === 'categories' && <Categories data={data} icon={icon} item={item}/>}
			{(name === 'location' || name === 'tag') && <LocationsTags data={data} icon={icon} item={item} name={name} numberOfItems={numberOfItems} />}
			{name === 'languages' && <Languages data={data} icon={icon} item={item} name={name} numberOfItems={numberOfItems} />}
			{(name !== 'location' && name !== 'tag' && name !== 'categories' && name !== 'languages') && <Other data={data} icon={icon} item={item} name={name} numberOfItems={numberOfItems} />}
		</div>
	);
};

const DisplayInformation = ({ item }) => {
	const { t } = useTranslation();
	const salaryConfig = {
		0: t('jobs.single.type.common.displayInformation.salaryConfig.hour'),
		1: t('jobs.single.type.common.displayInformation.salaryConfig.week'),
		2: t('jobs.single.type.common.displayInformation.salaryConfig.month')
	};

	const {categories, tags, locations} = useItem(item)

	const {config} = useConfig();

	let Mapper = [
		{
			name: 'categories',
			icon: <Briefcase/>,
			data: categories
		},
		{
			name: 'location',
			icon: <MapPin/>,
			data: locations,
			numberOfItems: 2,
		},
		{
			name: 'tag',
			icon: <Folder/>,
			data: tags,
			numberOfItems: 2
		},
	];

	if(item.type !== 'employer') {

		const ExtraMapper = [
			{
				name: 'salaryType',
				icon: <DollarSign/>,
				data: (item.salary_type !== null && item.salary_from !== null && item.salary_to !== null) ? [`${item.salary_from} - ${item.salary_to} / ${salaryConfig[item.salary_type]}`] : null,
			},
			{
				name: 'languages',
				icon: <Globe/>,
				data: (Array.isArray(item.languages) && item.languages.length > 0) ? item.languages : null,
				numberOfItems: 2,
			},
			{
				name: 'contract',
				icon: <FileText/>,
				data: (Array.isArray(item.contract) && item.contract.length > 0) ? item.contract.map(item => t(`config.appConfig.job.contract.${AppConfig.job.contract[item]}`)) : null,
				numberOfItems: 2,
			},
			{
				name: 'mode',
				icon: <Coffee/>,
				data: (Array.isArray(item.working_mode) && item.working_mode.length > 0) ? item.working_mode.map(item => t(`config.appConfig.job.working_mode.${AppConfig.job.working_mode[item]}`)) : null,
				numberOfItems: 2,
			},
			{
				name: 'time',
				icon: <Clock/>,
				data: (Array.isArray(item.working_time) && item.working_time.length > 0) ? item.working_time.map(item => t(`config.appConfig.job.working_time.${AppConfig.job.working_time[item]}`)) : null,
				numberOfItems: 2,
			}
		];

		Mapper = Mapper.concat(ExtraMapper)

	}

	return (
		<div className="d-flex flex-wrap">
			{Mapper.map((section, index) => (<React.Fragment key={index}><InformationItem name={section.name} icon={section.icon} data={section.data} numberOfItems={section.numberOfItems} item={item}></InformationItem></React.Fragment>))}
		</div>
	);
};

export default DisplayInformation;
