import TopBar from "../Profile/TopBar";
import './Assets/main.scss';
import {Col, Container, Row} from "react-bootstrap";
import PremiumFeatures from "./Parts/PremiumFeatures";
import PremiumPayment from "./Parts/PremiumPayment";
import useUser from "../../Hooks/ReduxHooks/useUser";
import i18n from "i18next";
import premiumTranslations from "./Translations/en/premiumTranslations.json";
import {useTranslation} from "react-i18next";

const Premium = () => {

	i18n.addResources('en', 'premium', premiumTranslations.premium);

	const { t } = useTranslation('premium');

	const {isMobile} = useUser();

	return(
		<div className="premium">
			<Container>
				<TopBar title={t('topBar')} backButton={true}/>
				<Row className="mt-5">
					{isMobile &&
						<Col className="mb-4">
							<PremiumPayment />
						</Col>
					}
					<Col md={8}>
						<PremiumFeatures />
					</Col>
					{!isMobile &&
						<Col>
							<PremiumPayment />
						</Col>
					}
				</Row>
			</Container>
		</div>
	)
}

export default Premium;