import React from "react";
import { CiSquareChevRight } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import useUser from "../../../Hooks/ReduxHooks/useUser";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";
import Auth from "../../Auth/Auth";

const BecomeSeller = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const { openPopup } = usePopup();

  const handleClick = (e) => {
    e.preventDefault();

    if (!user) {
      return openPopup(Auth, { type: "register" });
    }
    navigate("/account/settings?tab=account-type");
  };
  return (
    <div className="bg-primary d-flex align-items-center pt-3 pb-3 ps-md-5 ps-3 pe-3 rounded-4 become-seller">
      <p className="text-white mb-0 font-xxxl" style={{ lineHeight: 1.1 }}>
        Have any skills?
      </p>
      <a onClick={handleClick} href={"#"}>
        <div className="ms-3 font-xxl d-flex align-items-center pt-3 pb-3 pe-3 ps-4 rounded-4">
          <span className="me-2 become-seller-text">Become a Seller</span>
          <span>
            <CiSquareChevRight size={32} />
          </span>
        </div>
      </a>
    </div>
  );
};

export default BecomeSeller;
