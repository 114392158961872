import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import useSavePreviousLocation from './Hooks/useSavePreviousLocation';
import {useWebSocket} from "./Hooks/ReduxHooks/useWebSocket";
import useUser from "./Hooks/ReduxHooks/useUser";
import Auth from "./Components/Auth/Auth";
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import usePopup from "./Hooks/ReduxHooks/usePopup";
import useBackground from "./Hooks/ReduxHooks/useBackground";
import useMessage from "./Hooks/ReduxHooks/useMessage";
import GoToTop from "./Components/Parts/GoToTop";

const RequireSeller = () => {

	const {user} = useUser();

	if(user.account_type === 'searcher'){

		return <Navigate to={'/account/settings'}/>

	}

	return(
		<Outlet />
	)
}

const RequireAuth = () => {

	const {user} = useUser();

	if(!user) return <Navigate to={'/?login=first'}/>;

	return (
		<Outlet/>
	);

};

const RequireValidUserOrQuest = () => {
	useSavePreviousLocation();
	const {user, handleSetThumbnail, handleSetAvatar, handleSetImageLoading} = useUser();
	const {setProgress} = useBackground();
	const {register, unregister, isSocketActive, connect} = useWebSocket()
	const {openPopup} = usePopup();
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search);
	const {getUnreadCount, handleSetUnreadCount} = useMessage();

	useEffect(() => {
		if(queryParams.get('auth-popup')){
			openPopup(Auth, {type: queryParams.get('auth-popup')})
		}
		connect();
	}, []);

	useEffect(() => {
		if (!user) return;
		const handleUnreadConversations = (data) => {
			handleSetUnreadCount(data.data.count);
		}

		register('unreadConversations', handleUnreadConversations);

		return () => {
			unregister('unreadConversations', handleUnreadConversations);
		};
	}, [user]);

	useEffect(() => {
		const handleThumbnailUploadCompletedVideo = (data) => {
			handleSetThumbnail({ source: data.url });
			handleSetAvatar(data.preview);
			setProgress(0);
			handleSetImageLoading(false);
		};

		const handleThumbnailUploadCompletedImage = (data) => {
			handleSetThumbnail({ source: data.url, resource_type: data.type });
			handleSetAvatar(data.preview);
			setProgress(0);
			handleSetImageLoading(false);
		}

		register('thumbnail_upload_completed_video', handleThumbnailUploadCompletedVideo);
		register('thumbnail_upload_completed_image', handleThumbnailUploadCompletedImage)

		return () => {
			unregister('thumbnail_upload_completed_video', handleThumbnailUploadCompletedVideo);
			unregister('thumbnail_upload_completed_image', handleThumbnailUploadCompletedImage);
		}
	}, []);

	useEffect(() => {
		if(isSocketActive){
			getUnreadCount()
		}
	}, [isSocketActive]);

	return(
		<>
			<GoToTop />
			<Outlet />
		</>
	);

}

export {
	RequireAuth,
	RequireValidUserOrQuest,
	RequireSeller
};
