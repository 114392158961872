import React, {useEffect, useRef, useState} from 'react';
import {Form} from 'react-bootstrap';
import useAddressPredictions from '../../../../Hooks/useAddressPredictions';
import {MapPin, X} from 'react-feather';
import {useTranslation} from 'react-i18next';
import CloseOutside from '../../../Parts/CloseOutside';
import {Rings} from 'react-loader-spinner';
import Notification from '../../../../utils/NotificationUtils';
import {Configure, useInstantSearch} from 'react-instantsearch';
import LocationRadius from './LocationRadius';
import AnimatedDropdown, {AnimatedDropdownList, AnimatedDropdownListItem} from '../../../Parts/AnimatedDropdown';
import useQueryString from "../../../../Hooks/useQueryString";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import {getLocationName} from "../../../../utils/GoogleMapsUtils";
import {useNavigate} from 'react-router-dom';

const LocationClient = () => {

	const {t} = useTranslation();

	const [loading, setLoading] = useState(false);

	const {handleSetGeo} = useUser();

	const {addQuery} = useQueryString();

	const navigate = useNavigate();

	const handleSetLocation = async(position) => {

		const lat = position.coords.latitude;
		const lng = position.coords.longitude;
		const name = await getLocationName(lat, lng);

		addQuery('place', name.name);

		handleSetGeo({radius: 50, lat: lat, lng: lng, name: name.name, selected: true});

		setLoading(false);

		if (window.location.pathname !== '/') {

			navigate('/');

		}

	};

	const handleLocate = () => {

		setLoading(true);

		if(navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(handleSetLocation,
				(error) => {
					console.error('Error getting geolocation:', error);
					Notification.error(t('jobs.browse.search.where.notifications.notShared.text'), t('jobs.browse.search.where.notifications.notShared.title'));
				}
			);
		}
		else {
			console.error('Geolocation is not supported by this browser.');
			Notification.error(t('jobs.browse.search.where.notifications.notSupported.text'), t('jobs.browse.search.where.notifications.notSupported.title'));
		}
	};

	return (
		loading ?
			<Rings height="28" width="28" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/> :
			<MapPin width={20} onClick={handleLocate}/>
	);

};

const LocationInput = ({handleKeydown, handleFocus, loading, setAddress}) => {

	const {t} = useTranslation();

	const {geo, handleSetGeo} = useUser();

	const [locationValue, setLocationValue] = useState('');

	const handleKeyDown = (e) => {

		handleKeydown(e.target.value);

	};

	const handleChange = (e) => {

		setLocationValue(e.target.value);

	};

	const handleReset = () => {

		setAddress('');

		setLocationValue('')

		handleSetGeo({
			selected: false,
			radius: 50,
			lat: false,
			lng: false,
			name: '',
		});

	};

	return (
		<div className={'form-control-with-delete'}>
			<Form.Control className="font-weight-sm pe-5 " placeholder={t('jobs.browse.search.where.enterCity')} value={geo.name ? geo.name : locationValue} onChange={handleChange} onKeyUp={handleKeyDown} onFocus={handleFocus}/>
			{loading &&
				<Rings height="20" width="20" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
			{!geo.selected && <LocationClient/>}
			{geo.selected && !loading && <X width={20} onClick={handleReset}/>}
		</div>
	);
};

const Location = () => {

	const [open, setOpen] = useState(false);

	const {geo, handleSetGeo} = useUser();

	const {indexUiState} = useInstantSearch();

	const searchParams = new URLSearchParams(window.location.search);

	const {addQuery} = useQueryString();

	useEffect(() => {

		if('configure' in indexUiState) {

			if('aroundLatLng' in indexUiState.configure) {

				handleSetGeo({
					selected: true,
					// Get the place from the query string
					name: searchParams.get('place'),
					// Get the lat and lng from the configure state
					lat: indexUiState.configure.aroundLatLng.split(',')[0],
					lng: indexUiState.configure.aroundLatLng.split(',')[1].trim(),
					radius:  indexUiState.configure.aroundRadius / 1000
				})

			}

		}

	}, []);

	const handleChangePrediction = async(prediction) => {

		let placeId = prediction.place_id;

		const geocoder = new window.google.maps.Geocoder();

		await geocoder.geocode({placeId},

			(results) => {

				handleSetGeo({
					...geo,
					lat: results[0].geometry.location.lat(),
					lng: results[0].geometry.location.lng(),
					name: prediction.description,
					selected: true
				});

				// Add the place to the query string
				addQuery('place', prediction.description);

				setOpen(false);

			});

	};

	const {setAddress, loading, predictions, address} = useAddressPredictions();

	const DropdownPredictions = () => {
		return (
			<AnimatedDropdown className={'search-location-places'} isOpen={(open && predictions.length > 0) || (loading && address.length > 0)}>
				{loading ?
					<Rings height="20" width="20" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/> :
					<AnimatedDropdownList>
						{predictions.map((prediction, index) => <AnimatedDropdownListItem key={index} handleClick={() => handleChangePrediction(prediction)}>{prediction.description}</AnimatedDropdownListItem>)}
					</AnimatedDropdownList>}
			</AnimatedDropdown>
		);
	};

	return (
		<div className={'search-location'}>
			{geo.selected && <Configure aroundLatLng={geo.selected ? `${geo.lat}, ${geo.lng}` : false} aroundRadius={geo.radius * 1000}/>}
			<CloseOutside className={`search-location-place`} handleClose={() => setOpen(false)}>
				<LocationInput handleKeydown={setAddress} handleFocus={() => setOpen(true)} setAddress={setAddress} />
				<DropdownPredictions/>
			</CloseOutside>
			{geo.selected && <LocationRadius className={'ms-md-2'}/>}
		</div>
	);

};

export default Location;