// messageSlice.js
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {useWebSocket} from "../../Hooks/ReduxHooks/useWebSocket";

const initialState = {
	conversationsData: { count: 0, items: [], last: null },
	conversationData: {},
	loadings: {
		conversationsData: false,
		conversationData: false,
		getConversationHistory: false,
		sendMessage: false,
		conversationsPagination: false,
		conversationHistoryPagination: false,
	},
	conversationHistory: {count: 0, items: [], last: null},
	chatID: null,
	isNewMessage: false,
	unreadCount: 0,
	historyLimit: false,
};

const messageSlice = createSlice({
	name: 'newMessage',
	initialState,
	reducers: {
		setConversationsData(state, action) {
			state.conversationsData = action.payload;
		},
		setConversationData(state, action) {
			state.conversationData = action.payload
		},
		setLoadings(state, action) {
			const { field, value } = action.payload;
			state.loadings[field] = value;
		},
		setChatID(state, action) {
			state.chatID = action.payload;
		},
		setConversationHistory(state, action) {
			state.conversationHistory = action.payload;
		},
		setIsNewMessage(state, action) {
			state.isNewMessage = action.payload;
		},
		setUnreadCount(state, action){
			state.unreadCount = action.payload;
		},
		setHistoryLimit(state, action){
			state.historyLimit = action.payload;
		}
	},
});

export const {
	setConversationsData,
	setLoadings,
	setConversationData,
	setChatID,
	setConversationHistory,
	setIsNewMessage,
	setUnreadCount,
	setHistoryLimit
} = messageSlice.actions;

export default messageSlice.reducer;
