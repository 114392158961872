import { useEffect, useState } from 'react';

const useLoadGoogleMaps = () => {

	const [isLoaded, setIsLoaded] = useState(false);

	const [error, setError] = useState(null);
	useEffect(() => {
		const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_URL_KEY}&libraries=geometry,places&language=us&region=US&v=quarterly&callback=initMap&loading=async`;

		const existingScript = document.querySelector(`script[src="${mapsURL}"]`);

		if (existingScript) {
			if (window.google && window.google.maps) {
				setIsLoaded(true);
			} else {
				existingScript.addEventListener('load', () => setIsLoaded(true));
				existingScript.addEventListener('error', (e) => setError(e));
			}
			return;
		}

		const googleMapScript = document.createElement('script');
		googleMapScript.src = mapsURL;
		googleMapScript.async = true;
		googleMapScript.defer = true;

		window.initMap = () => {
			setIsLoaded(true);
		};

		googleMapScript.onerror = (e) => {
			setError(e);
		};

		document.body.appendChild(googleMapScript);

		return () => {
			if (googleMapScript) {
				googleMapScript.remove();
			}
			delete window.initMap;
		};
	}, []);

	return { isLoaded, error };
};

export default useLoadGoogleMaps;
