import { createSlice } from "@reduxjs/toolkit";
import React from "react";

const initialState = {
	popupContent: null,
	popupProps: null,
	popupsContent: []
};

const popupSlice = createSlice({
	name: 'popup',
	initialState,
	reducers: {
		showPopup(state, action) {
			const { component, props } = action.payload;
			state.popupContent = component;
			state.popupProps = props;
		},
		createPopup(state, action) {
			const { component, name } = action.payload;
			state.popupsContent = [...state.popupsContent, {component, visible: false, name}];
		},
		popupVisible(state, action) {
			const {name, visible} = action.payload;
			state.popupsContent = state.popupsContent.map(el => {
				if (el.name === name) {
					return {
						...el,
						visible: visible
					};
				}
				return el;
			});
		},
		hidePopup(state) {
			state.popupContent = null;
			state.popupProps = null;
		}
	}
});

export const { showPopup, hidePopup, createPopup, popupVisible } = popupSlice.actions;

export default popupSlice.reducer;