import {useDispatch, useSelector} from "react-redux";
import {
	handleRefresh,
	setAvatar,
	setImageLoading,
	setIsMobile,
	setThumbnail,
	setUser,
	setGeo
} from "../../redux/slices/userSlice";
import {signOut} from 'aws-amplify/auth';

const useUser = () => {

	const dispatch = useDispatch()

	const {
		user,
		indexStatus,
		isMobile,
		isIOS,
		isAdmin,
		lang,
		observations,
		thumbnail,
		avatar,
		loadingImage,
		loading,
		geo
	} = useSelector((state) => state.user);

	const handleLogout = async () => {

		await signOut();

		dispatch(handleRefresh())

	}

	const handleGlobalLogout = async () => {

		await signOut({global: true});

		dispatch(handleRefresh());

	}

	return{
		user,
		indexStatus,
		isMobile,
		isIOS,
		isAdmin,
		lang,
		observations,
		thumbnail,
		avatar,
		loading,
		loadingImage,
		geo,
		handleSetGeo: (geo) => dispatch(setGeo(geo)),
		handleSetUser: (user) => dispatch(setUser(user)),
		handleSetIsMobile: (value) => dispatch(setIsMobile(value)),
		handleLogout,
		handleGlobalLogout,
		handleRefresh: () => dispatch(handleRefresh()),
		handleSetThumbnail: (thumbnail) => dispatch(setThumbnail(thumbnail)),
		handleSetAvatar: (avatar) => dispatch(setAvatar(avatar)),
		handleSetImageLoading: (loading) => dispatch(setImageLoading(loading))
	}
}

export default useUser;