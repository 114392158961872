import {useWebSocket} from "./useWebSocket";
import {useDispatch, useSelector} from "react-redux";
import {
	setConversationsData,
	setLoadings,
	setChatID,
	setConversationData,
	setConversationHistory,
	setIsNewMessage,
	setUnreadCount,
	setHistoryLimit
} from "../../redux/slices/messageSlice";
const useMessage = () => {
	const {socket, send} = useWebSocket();
	const dispatch = useDispatch();
	const {
		conversationsData,
		loadings,
		chatID,
		conversationData,
		conversationHistory,
		isNewMessage,
		unreadCount,
		historyLimit
	} = useSelector((state) => state.message);

	const getConversations = (last) => {
		if(!socket){
			return
		}
		const getConversationData = {
			action: 'getConversations',
		}

		if(last){
			handleSetLoadings('conversationsPagination', true)
			getConversationData.data = {
				last: last
			}
		}

		else{
			handleSetLoadings('conversationsData', true)
			handleSetConversationsData({ count: 0, items: [], last: null })
		}

		send(JSON.stringify(getConversationData));
	};

	const getConversation = () => {

		handleSetLoadings('conversationData', true);
		const getConversationData = JSON.stringify({
			action: 'getConversation',
			data: {
				id: chatID
			}
		});
		send(getConversationData);
	}

	const getConversationHistory = (last) => {
		const getHistoryData = {
			action: 'getConversationHistory',
			data: {
				id: chatID,
			},
		}
		if(last){
			handleSetLoadings('conversationHistoryPagination', true);
			getHistoryData.data.last = last
		}
		else{
			handleSetLoadings('conversationHistory', true);
		}
		send(JSON.stringify(getHistoryData));
	}

	const archiveConversation = () => {
		if(!socket){
			return
		}
		const archiveConversationData = JSON.stringify({
			action: 'archiveConversation',
			data: {
				id: chatID
			}
		})
		send(archiveConversationData)
	}

	const getUnreadCount = () => {
		if(!socket){
			return
		}
		const getUnreadCountData = JSON.stringify({
			action: 'getUnreadCount'
		})
		send(getUnreadCountData)
	}

	const sendMessage = (message) => {
		handleSetLoadings('sendMessage', true)

		const messageData = JSON.stringify({
			action: 'sendMessage',
			data: {
				message: message,
				id: chatID
			}
		});
		send(messageData)
	}

	const handleSetConversationsData = (data) => {
		dispatch(setConversationsData(data));
	}

	const handleSetLoadings = (key, value) => {
		dispatch(setLoadings({ field: key, value: value }));
	}

	const handleSetChatID = (id) => {
		dispatch(setChatID(id));
	}

	const handleSetConversationData = (data) => {
		dispatch(setConversationData(data))
	}

	const handleSetConversationHistory = (data) => {
		dispatch(setConversationHistory(data))
	}

	const handleSetIsNewMessage = (data) => {
		dispatch(setIsNewMessage(data));
	}

	const handleSetUnreadCount = (data) => {
		dispatch(setUnreadCount(data));
	}

	const handleSetHistoryLimit = (boolean) => {
		dispatch(setHistoryLimit(boolean))
	}

	return{
		getConversations,
		handleSetConversationsData,
		conversationsData,
		loadings,
		handleSetLoadings,
		handleSetChatID,
		chatID,
		getConversation,
		handleSetConversationData,
		conversationData,
		conversationHistory,
		handleSetConversationHistory,
		getConversationHistory,
		sendMessage,
		handleSetIsNewMessage,
		isNewMessage,
		unreadCount,
		archiveConversation,
		getUnreadCount,
		handleSetUnreadCount,
		handleSetHistoryLimit,
		historyLimit
	}
}

export default useMessage;