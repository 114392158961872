import React, { useCallback, useEffect, useRef } from "react";
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import {Loader} from "./Loaders";
import useUser from "../../Hooks/ReduxHooks/useUser";
import ProfileStatus from "../Profile/Common/ProfileStatus";

const TabsNavItem = React.memo(function TabsNavItem({ tab, navPillScrollRef }) {
	const navItemRef = useRef();

	const scrollToCenter = () => {
		if (navItemRef.current) {
			const navPillScroll = navPillScrollRef.current;
			const item = navItemRef.current;

			const containerWidth = navPillScroll.offsetWidth;
			const itemWidth = item.offsetWidth;
			const itemOffsetLeft = item.offsetLeft;

			const newScrollLeft = itemOffsetLeft - (containerWidth / 2) + (itemWidth / 2);

			navPillScroll.scrollTo({
				left: Math.max(0, Math.min(newScrollLeft, navPillScroll.scrollWidth - containerWidth)),
				behavior: 'smooth'
			});
		}
	};

	return tab.visible !== false ? (
		tab.isLink ?
			<div className={'d-flex align-items-center mt-0 m-lg-3'}>
				{tab.title}
			</div>
			:
			<Nav.Item id={tab.section_id} ref={navItemRef} onClick={scrollToCenter}>
				<Nav.Link eventKey={`${tab.section_id}`}>
					{tab.title}
				</Nav.Link>
			</Nav.Item>
	) : null;
});

const TabsNav = ({ configuration, tab }) => {
	const navPillScrollRef = useRef();

	useEffect(() => {
		if (navPillScrollRef.current && tab !== null) {
			const activeTab = navPillScrollRef.current.querySelector(`#${tab}`);
			if (activeTab) {
				const containerWidth = navPillScrollRef.current.offsetWidth;
				const itemWidth = activeTab.offsetWidth;
				const itemOffsetLeft = activeTab.offsetLeft;

				navPillScrollRef.current.scrollLeft = itemOffsetLeft - (containerWidth / 2) + (itemWidth / 2);
			}
		}
	}, [tab]);

	return (
		<Col lg={3} className="position-sticky top-0 bg-white nav-pills-wrapper mb-md-0 mb-3">
			<Nav variant="pills" className="flex-row flex-lg-column mb-0" ref={navPillScrollRef}>
				{configuration.map((tab, index) => (
					<TabsNavItem key={index} tab={tab} navPillScrollRef={navPillScrollRef} />
				))}
			</Nav>
		</Col>
	);
};

const TabsContentPane = React.memo(function TabsContentPane({ tab }) {
	return tab.visible !== false ? (
		<Tab.Pane eventKey={`${tab.section_id}`}>
			{tab.components}
		</Tab.Pane>
	) : null;
});

const TabsContent = ({ configuration, displayLoader, actionComponent, children }) => {
	return (
		<Col lg={9}>
			{children}
			<Tab.Content>
				{displayLoader ? <Loader /> : configuration.map((tab, index) => <TabsContentPane key={index} tab={tab} />)}
			</Tab.Content>
			{actionComponent}
		</Col>
	);
};

const TabsComponent = ({ configuration, sectionDefault = '', scrollTopElement, handleChange = () => {}, displayLoader = false, actionComponent, children }) => {
	const url = new URLSearchParams(window.location.search);
	const tab = url.get('tab');
	const { isMobile } = useUser();

	const active = () => {
		return tab || sectionDefault;
	};

	const handleChangeInit = useCallback((key, e) => {
		const exists = url.get('tab');
		if (exists !== null) {
			url.set('tab', key);
		} else {
			url.append('tab', key);
		}
		window.history.pushState({}, '', `${window.location.origin}${window.location.pathname}?${url.toString()}`);
		handleChange(key);
	}, [url, handleChange]);

	return (
		<Tab.Container id="edit-cv-tab" defaultActiveKey={active} onSelect={(key, e) => handleChangeInit(key, e)}>
			{isMobile && <ProfileStatus />}
			<Row className="mt-5 d-flex justify-content-between position-relative">
				<TabsNav configuration={configuration} tab={tab} />
				<TabsContent configuration={configuration} displayLoader={displayLoader} actionComponent={actionComponent}>
					{children}
				</TabsContent>
			</Row>
		</Tab.Container>
	);
};

export default TabsComponent;