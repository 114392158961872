import React from "react";
import Share from '../../Parts/Misc/Share';
import MoreOptions from '../../Parts/Misc/MoreOptions';
import useHide from '../../../Hooks/useHide';
import ObserverButton from "../../Browse/Parts/ObserverButton";

const SingleHeaderRight = ({item}) => {

	const hide = useHide(item);

	return (
		<div className={'d-flex align-items-center float-end header-actions'}>
			<ObserverButton isText={false} uid={item.userId}/>
			<Share item={item} useText={true}/>
			<MoreOptions item={item} hide={hide} options={['Share', 'Observe', 'ShowSimilar', 'NoMore', 'Report', 'Close']}/>
		</div>

	);

};

export default SingleHeaderRight;
