import React, {useEffect} from "react";
import {Loader} from "../../../Parts/Loaders";
import {Alert, Table} from "react-bootstrap";
import timestampToDate from "../../../../utils/timestampToDate";
import InvoiceActions from "../Parts/InvoiceActions";
import usePayment from "../../../../Hooks/ReduxHooks/usePayment";

const TabBillingHistory = () => {

	const {handleFetchBillingInvoices, invoicesData, loadings} = usePayment();

	useEffect(() => {
		handleFetchBillingInvoices()
	}, []);

	const formatPrice = (price) => {
		return (price / 100).toFixed(2);
	}

	if(!invoicesData && !loadings.fetchBillingInvoices){
		return(
			<Alert className={'font-family-open-sans'} variant={'info'}>
				No invoices
			</Alert>
		)
	}

	return (
		<>
			<h4 className="m-0 title">Billing History</h4>
			{loadings.fetchBillingInvoices ?
				<Loader />
				:
				<>
					<Table striped>
						<thead>
						<tr>
							<th>Date</th>
							<th>Document Type</th>
							<th>Service</th>
							<th>Amount</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{invoicesData.map((invoice, index) => (
							<>
								{invoice.status === 'paid' &&
									<tr key={index}>
										<td>{timestampToDate(invoice.status_transitions.paid_at * 1000)}</td>
										<td>Invoice</td>
										<td>{invoice.lines.data[0].description}</td>
										<td>${formatPrice(invoice.amount_paid)}</td>
										<td>
											<InvoiceActions pdfLink={invoice.invoice_pdf} />
										</td>
									</tr>
								}
							</>
						))}
						</tbody>
					</Table>

				</>
			}
		</>

	);

};
export default TabBillingHistory;
