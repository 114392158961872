import React, {useState} from 'react';
import {MoreVertical} from 'react-feather';
import CloseOutside from '../CloseOutside';
import useUser from "../../../Hooks/ReduxHooks/useUser";
import useHide from '../../../Hooks/useHide';
import {useTranslation} from 'react-i18next';
import usePopup from '../../../Hooks/ReduxHooks/usePopup';
import useMessage from '../../../Hooks/ReduxHooks/useMessage';
import ShareWindow from './ShareWindow';
import Auth from '../../Auth/Auth';
import ReportViolation from './ReportViolation';
import AnimatedDropdown from '../AnimatedDropdown';
import useWatchlist from "../../../Hooks/ReduxHooks/useWatchlist";
import useQueryString from "../../../Hooks/useQueryString";
import {useInstantSearch} from "react-instantsearch";

const ActionHide = ({item, setVisible}) => {

    const {user} = useUser();

    const {openPopup} = usePopup();

    const {t} = useTranslation();

    const {handleHide, isHidden, loading} = useHide(item.uuid);

    if (isHidden) {
        return;
    }

    const handleClick = () => {

        if (user) {
            handleHide();
            setVisible(false);
        } else {
            openPopup(Auth, {type: 'login'});
        }
    }

    return (
        <li className={`list-group-item bg-transparent'`} onClick={handleClick}>
            {loading ? 'Please wait' : t('parts.misc.moreOptionsPopup.options.noMore.title')}
        </li>
    );
};

const ActionShare = ({item, setVisible}) => {

    const {t} = useTranslation();

    const {openPopup} = usePopup();

    const handleClick = () => {

        openPopup(ShareWindow, {item})

        setVisible(false);

    }

    return (
        <>
            <li className={`list-group-item bg-transparent`} onClick={handleClick}>
                {t('parts.misc.moreOptionsPopup.options.share.title')}
            </li>
        </>
    );
};

const ActionObserve = ({item, setVisible}) => {

    const {user} = useUser();

    const {observed, handleObserve} = useWatchlist(item.userId || item.uuid);

    if (user && (user.id === item.uid || user.id === item.id)) return null;

    return (
        <li className={`list-group-item bg-transparent`} onClick={() => handleObserve(item.userId || item.uuid)}>
            {observed ? 'Cancel observation' : 'Observe'}
        </li>
    );

}

const ActionSimilar = ({item, setVisible}) => {

    const {t} = useTranslation();

    const {setIndexUiState} = useInstantSearch();

    const {addQuery} = useQueryString();

    const showSimilar = () => {

        setIndexUiState([])

        addQuery('similar', item.uuid || item.userId);

    }

    return (
        <li className={`list-group-item bg-transparent`} onClick={showSimilar}>
            {t('parts.misc.moreOptionsPopup.options.showSimilar.title')}
        </li>
    );

}

const ActionReport = ({item, setVisible}) => {

    const {t} = useTranslation();

    const {user} = useUser();

    const {openPopup} = usePopup();

    const handleClick = () => {

        openPopup(user ? ReportViolation : Auth, user ? {item} : {type: 'login'})

        setVisible(false);

    }

    return (
        <>
            <li className={`list-group-item bg-transparent`} onClick={handleClick}>
                {t('parts.misc.moreOptionsPopup.options.report.title')}
            </li>
        </>
    );

}

const ActionClose = ({item, setVisible}) => {

    const {t} = useTranslation();

    const handleClick = () => {

        setVisible(false);

    };

    return (
        <li className={`list-group-item bg-transparent`} onClick={handleClick}>
            {t('parts.misc.moreOptionsPopup.options.close.title')}
        </li>
    );
}

const ActionArchive = ({item, setVisible}) => {

    const {archiveConversation} = useMessage();

    const handleClick = () => {

        archiveConversation();

        setVisible(false);

    }

    return (
        <li onClick={handleClick}>
            Archive chat
        </li>
    )

}

const MoreOptionsContent = ({setVisible, item, options = []}) => {

    return (
        <ul className={'p-0'}>
            {options.includes('Hide') && <ActionHide item={item} setVisible={setVisible}/>}
            {options.includes('Share') && <ActionShare item={item} setVisible={setVisible}/>}
            {options.includes('Observe') && <ActionObserve item={item} setVisible={setVisible}/>}
            {options.includes('ShowSimilar') && <ActionSimilar item={item} setVisible={setVisible}/>}
            {options.includes('Report') && <ActionReport item={item} setVisible={setVisible}/>}
            {options.includes('ArchiveConversation') && <ActionArchive item={item} setVisible={setVisible}/>}
            {options.includes('Close') && <ActionClose item={item} setVisible={setVisible}/>}
        </ul>
    );
};

const MoreOptions = ({item, options}) => {

    const [visible, setVisible] = useState(false);

    const {isIOS} = useUser();

    const toggleVisible = () => setVisible(prevState => !prevState)

    return (
        <CloseOutside handleClose={() => setVisible(false)}>
            <div className={'item-more-options'}>
                <button type="button" className={'more-options'} onClick={!isIOS ? toggleVisible : undefined} onTouchStart={isIOS ? toggleVisible : undefined}>
                    <div title="Więcej opcji" className={'more-options-icon'}>
                        <MoreVertical size={23}/>
                    </div>
                </button>
                <AnimatedDropdown isOpen={visible} className={'dropdown-more-options end-0'}>
                    {visible && <MoreOptionsContent setVisible={setVisible} item={item} options={options}/>}
                </AnimatedDropdown>
            </div>
        </CloseOutside>
    )
}

export default MoreOptions;
