import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {CiChat1, CiGrid41, CiStar, CiUser} from "react-icons/ci";
import CloseIcon from '../../Parts/CloseIcon';
import usePopup from '../../../Hooks/ReduxHooks/usePopup';
import useUser from '../../../Hooks/ReduxHooks/useUser';
import Auth from '../../Auth/Auth';

const HamburgerMenu = ({isMenuActive, setIsMenuActive}) => {

	const { t } = useTranslation();

	const {handleLogout, user} = useUser();

	const sellConfig = [
		{
			linkText: t('parts.header.topBar.buttons.profile.title'),
			linkValue: "account"
		},
		{
			linkText: t('parts.header.topBar.buttons.profile.menu.edit'),
			linkValue: "account/edit"
		},
		{
			linkText: t('parts.header.topBar.buttons.profile.menu.invoicesAndPayments'),
			linkValue: "account/billing"
		},
		{
			linkText: t('parts.header.topBar.buttons.profile.menu.settings'),
			linkValue: "account/settings"
		}
	]

	const buyerConfig = [
		{
			linkText: t('parts.header.topBar.buttons.profile.menu.settings'),
			linkValue: "account/settings"
		}
	]

	const LinksPopup = () => {
		return(
			<ul className={'ps-0'}>
				{(user.account_type === 'searcher' ? buyerConfig : sellConfig).map((link, index) => <LinkWrapper link={link} key={index} />)}
			</ul>
		)
	}

	const LinkWrapper = ({link}) => {
		return(
			<li>
				<Link className={'ps-5 font-xxl text-primary d-inline-block w-100'} onClick={() => setIsMenuActive(false)} to={link.linkValue}>{link.linkText}</Link>
			</li>
		)
	}

	const Logout = () => {

		return(
			<div className="w-100 d-flex justify-content-center mb-3">
				<button className="mt-3 btn-has-loader btn btn-outline-primary text-uppercase w-100" onClick={handleLogout}>Log Out</button>
			</div>
		)
	}

	return(
		<div className={`position-fixed d-flex flex-column justify-content-between h-100 top-0 hamburger-menu ${isMenuActive ? 'active' : ''}`}>
			<div>
				<CloseIcon additionalStyles="mt-3 me-3" handleClose={() => setIsMenuActive(false)} />
				<LinksPopup />
			</div>
			<div className={'me-3 ms-3'}>
				{user.account_type === 'searcher' &&
					<Link to="/account/settings" className="btn btn-primary mt-2 w-100">
						Become a Seller
					</Link>
				}
				<Logout />
			</div>
		</div>
	)
}

const RightMenu = ({setIsMenuActive, User}) => {

	const { t } = useTranslation();

	const navigate = useNavigate()

	const {openPopup} = usePopup();

	const {user} = useUser()

	const rightMenuConfig = [
		{
			id: 'messages',
			type: 'button',
			name: t('parts.bottomBar.menuItems.notifications'),
			icon: <CiChat1/>,
			like: '/messages',
			counter: User ? (User.unread ? User.unread.notifications : null) : null,
			function: () => User ? navigate('/messages') : openPopup(Auth, { type: 'login' })
		},
		{
			id: 'observations',
			type: 'button',
			name: t('parts.bottomBar.menuItems.observations'),
			icon: <CiStar/>,
			like: '/observations',
			function: () => User ? navigate('/observations') : openPopup(Auth, { type: 'login' })
		},
		{
			id: 'account',
			type: 'button',
			name: t('parts.bottomBar.menuItems.myProfile'),
			link: '',
			icon: user && user.avatar ? <img className="rounded-5" src={user.avatar} /> : <CiUser />,
			like: '/account',
			function: () => User ? setIsMenuActive(prevState => !prevState) : openPopup(Auth, { type: 'login' })
		},
	];

	const location = useLocation();

	return (
		rightMenuConfig.map(item => {
			const isActive = location.pathname.startsWith(item.like)
			return (
				<li className="app-bottom-bar-item" key={item.link}>
					{item.type === 'link' &&
						<Link to={item.link ? item.link : location.pathname} className={`${isActive ? 'active app-bottom-bar-link' : 'app-bottom-bar-link'}`}>{item.icon}<span>{item.name}</span></Link>
					}
					{item.type === 'button' &&
						<button onClick={item.function} className={`${isActive ? 'active app-bottom-bar-link' : 'app-bottom-bar-link'}`}>{item.icon}<span>{item.name}</span></button>
					}
				</li>
			);
		})
	);

};

const BottomBar = () => {

	const navigate = useNavigate()

	const location = useLocation();

	const [isMenuActive, setIsMenuActive] = useState(false)

	const {user} = useUser();

	const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
	const [hidden, setHidden] = useState(false);
	const [lastScrollTop, setLastScrollTop] = useState(0);

	const handleScroll = () => {
		const currentScrollPos = window.pageYOffset;
		const scrollDifference = currentScrollPos - lastScrollTop;

		if (location.pathname !== '/') {
			setHidden(false);
		}
		else {
			if (currentScrollPos > 250 && scrollDifference > 50) {
				setHidden(false);
			}
			else if (scrollDifference < -50) {
				setHidden(true);
			}
			else if(currentScrollPos < 250){
				setHidden(false);
			}
		}

		setLastScrollTop(currentScrollPos);
		setPrevScrollPos(currentScrollPos);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [prevScrollPos]);

	const handleBrowse = () => {
		navigate('/')
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		})
	}

	return (
		<>
			{user ? <HamburgerMenu isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/> : ''}
			<div className={`app-bottom-bar ${hidden ? 'hidden' : 'show'}`}>
				<div className={'container d-flex'}>
					<ul>
						<li className="app-bottom-bar-item">
							<button className="app-bottom-bar-link" onClick={handleBrowse}>
								<CiGrid41/> Explore
							</button></li>
					</ul>
					<ul className={'right'}>
						<RightMenu setIsMenuActive={setIsMenuActive} User={user}/>
					</ul>
				</div>
			</div>
		</>
	);

};

export default BottomBar;
