import axios from 'axios';

export const getLocationName = async(lat, lng) => {

	if(!lat || !lng) {

		console.log('no lat or lng');

		return {
			name: 'Unknown location',
			id: ''
		};
	}

	try {

		const {data} = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAPS_URL_KEY}`);

		const firstResult = data.results?.[0];

		if(firstResult) {

			const name = firstResult.formatted_address;

			return {
				name: name,
				id: firstResult.place_id
			};

		}

	} catch(error) {

		console.log('Error getting location name', error);

	}

	return {
		name: 'Unknown location',
		id: ''
	};

};

export const Default = {
	mapId: 'a96f9968795361fd',
	center: {
		lat: 47.751076,
		lng: -120.740135
	},
	zoom: 12
};
